import { type ReactNode, createContext, useCallback, useState } from 'react';

import { useTemplate } from '../hooks/useTemplate';

interface IFormContext {
  handleChangeForm: (path: string, value: any) => void;
  onGoBackToInitialScreenAndResetForm: () => void;
  removePath: (path: string) => void;
  form: any;
}

interface IFormProviderProps {
  children: ReactNode;
}

export const FormContext = createContext({} as IFormContext);

export function FormProvider({ children }: IFormProviderProps) {
  const { handleTemplateChange } = useTemplate();

  const [form, setForm] = useState({});

  const onGoBackToInitialScreenAndResetForm = useCallback(() => {
    handleTemplateChange('not-answered');
    setForm({});
  }, [handleTemplateChange]);

  const handleChangeForm = useCallback(
    (path: string, value: string | number | boolean) => {
      setForm((prevState) => ({
        ...prevState,
        [path]: value,
      }));
    },
    []
  );

  const removePath = useCallback(
    (path: string) => {
      const formDataCopy = { ...form };

      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete formDataCopy[path];

      setForm({ ...formDataCopy });
    },
    [form]
  );

  return (
    <FormContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        handleChangeForm,
        onGoBackToInitialScreenAndResetForm,
        removePath,
        form,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}
