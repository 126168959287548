import {
  useEffect,
  useState,
  useContext,
  createContext,
  type ReactNode,
  type ChangeEvent,
} from 'react';

import { formatPhoneNumber } from '../../modules/shared/phoneNumber/utils/formatPhoneNumber';

import { axiosInstance } from '../../utils/axiosInstance';

import { schemaPhone } from '../../schemas/phone';

import { showToast } from '../../components/atoms/Toast/Toast';

export interface ISignInContext {
  isPinSending: boolean;
  hasPhoneNumber: boolean;
  phone: {
    onlyNumbers: string;
    formatted: string;
  };
  pinScreen: {
    i18n: {
      subtitle: string;
    };
    urls: {
      validate: string;
      resend: string;
    };
  };
  handleUserPhoneNumber: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSendPinToNumber: () => void;
}

interface ISignInProviderProps {
  children: ReactNode;
}

interface ILinksApiResponse {
  name: string;
  href: string | null;
}

interface ILoginApiResponse {
  message: string;
  _links: {
    validate: ILinksApiResponse;
    resend: ILinksApiResponse;
    self: ILinksApiResponse;
  };
}

interface IURLs {
  validate: string;
  resend: string;
}

const NUMBER_IS_INVALID_TEXT =
  'Por favor, insira um número de telefone válido.';

const DEFAULT_ERROR_MESSAGE =
  'Tivemos um problema ao validar o telefone, tente novamente em alguns instantes.';

const DEFAULT_SUCCESS_MESSAGE =
  'O código de validação foi enviado para o seu telefone.';

export const SignInContext = createContext({} as ISignInContext);

export const SignInProvider = ({ children }: ISignInProviderProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedNumber, setFormattedNumber] = useState('');
  const [hasPhoneNumber, setHasPhoneNumber] = useState(false);
  const [isPinSending, setIsPinSending] = useState(false);
  const [urls, setUrls] = useState<IURLs>(null);
  const [message, setMessage] = useState<string>('');

  async function handleSendPinToNumber() {
    if (!phoneNumber) {
      setHasPhoneNumber(false);

      showToast({
        message: NUMBER_IS_INVALID_TEXT,
        type: 'warning',
        id: 'invalid-number',
      });

      return;
    }

    setIsPinSending(true);

    const { success } = schemaPhone.safeParse({
      phone: phoneNumber,
    });

    if (!success) {
      showToast({
        message: NUMBER_IS_INVALID_TEXT,
        type: 'warning',
        id: 'invalid-number',
      });

      setIsPinSending(false);

      return;
    }

    const requestOptions = {
      url: `${process.env.NEXT_PUBLIC_API_MOBILE_URL}/v1/login`,
      method: 'POST',
      data: {
        phone: phoneNumber,
        target: 'user',
      },
      headers: {
        'Accept-Language': 'pt-BR',
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    } as const;

    try {
      const authLoginResponseData = await axiosInstance<ILoginApiResponse>(
        requestOptions,
        false
      );

      const { data } = authLoginResponseData;

      showToast({
        message: DEFAULT_SUCCESS_MESSAGE,
        type: 'success',
        id: 'success-sending-pin',
      });

      setUrls({
        validate: data._links.validate.href,
        resend: data._links.resend.href,
      });
      setMessage(data.message);
      setHasPhoneNumber(true);
    } catch (error) {
      const toastMessage =
        error.response.data.status === 404
          ? 'Você não está cadastrado. Baixe nosso App e cadastre-se já, é grátis!'
          : DEFAULT_ERROR_MESSAGE;

      showToast({
        message: toastMessage,
        type: 'error',
        id: 'error-sending-pin',
      });

      setHasPhoneNumber(false);
    } finally {
      setIsPinSending(false);
    }
  }

  function handleUserPhoneNumber(event: ChangeEvent<HTMLInputElement>) {
    const phoneValue = event.target.value;

    setPhoneNumber(phoneValue);
    setFormattedNumber(phoneValue);
  }

  useEffect(() => {
    const onlyPhoneNumbers = phoneNumber.replace(/\D/g, '');
    const containsElevenNumber = onlyPhoneNumbers.length === 11;

    if (containsElevenNumber) {
      const formattedNumberValue = formatPhoneNumber({
        phone: phoneNumber,
      });

      setFormattedNumber(formattedNumberValue);
    }
  }, [phoneNumber]);

  return (
    <SignInContext.Provider
      value={{
        isPinSending,
        hasPhoneNumber,
        phone: {
          onlyNumbers: phoneNumber,
          formatted: formattedNumber,
        },
        pinScreen: {
          i18n: {
            subtitle: message,
          },
          urls,
        },
        handleUserPhoneNumber,
        handleSendPinToNumber,
      }}
    >
      {children}
    </SignInContext.Provider>
  );
};

export function useSignIn() {
  return useContext(SignInContext);
}
