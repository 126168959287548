import { Banner, Button, Container, Paragraph } from 'darrius';

import { type IFaqCallToAction } from '../../../locales/@interfaces/shared/request/faqCallToAction';
import Link from 'next/link';

export function RequestFaqCallToAction({
  button,
  text,
  title,
}: IFaqCallToAction) {
  return (
    <Banner as="section">
      <Paragraph title={title} text={text} size="2" headline />

      <Container>
        <Link href={button.link} passHref legacyBehavior>
          <Button.Link
            className="post-request-faq_button"
            variation="secondary"
          >
            {button.text}
          </Button.Link>
        </Link>
      </Container>
    </Banner>
  );
}
