import { memo } from 'react';

import { useForm } from '../../hooks/useForm';

import { isEmpty } from '../../utils/isEmpty';
import { shallowEqual } from '../../utils/shallowEqual';
import { checkComponentIsVisible } from '../../utils/checkComponentIsVisible';

import { TestAndRender } from '../TestAndRender';

import { type TGroupProps } from './@interfaces';
import { type TUiSchemaParam } from '../@interfaces';

export function GroupComponent({
  schema,
  uiSchema,
  dataSchema,
  renderers,
}: TGroupProps) {
  const { form } = useForm();

  const { elements } = uiSchema;

  if (isEmpty(elements)) {
    return null;
  }

  return (
    <>
      {elements.map((child) => {
        if (!checkComponentIsVisible(child.rule, form)) return null;

        return (
          <fieldset key={child.scope} style={{ border: 0 }}>
            <TestAndRender
              schema={schema}
              uiSchema={child}
              dataSchema={dataSchema}
              renderers={renderers}
            />
          </fieldset>
        );
      })}
    </>
  );
}

export function groupPropsAreEquals(
  prevPropsGroup: Readonly<TGroupProps>,
  nextPropsGroup: Readonly<TGroupProps>
) {
  return (
    shallowEqual(prevPropsGroup.schema, nextPropsGroup.schema) &&
    shallowEqual(prevPropsGroup.uiSchema, nextPropsGroup.uiSchema) &&
    shallowEqual(prevPropsGroup.dataSchema, nextPropsGroup.dataSchema) &&
    JSON.stringify(prevPropsGroup.renderers) ===
      JSON.stringify(nextPropsGroup.renderers)
  );
}

export const Group = memo(GroupComponent, groupPropsAreEquals);

export const GroupTester = (uiSchema: TUiSchemaParam) =>
  uiSchema.type === 'Group';
