import { Col, Container, Paragraph, Row } from 'darrius';

import { TextareaControl } from './components/TextareaControl';

import { type TUiSchemaParam } from '../@interfaces';
import { type ISurveyTextareaProps } from './@interfaces';

export function Textarea({
  schema,
  uiSchema,
  path,
  handleChange,
}: ISurveyTextareaProps) {
  const ownProps = {
    title: schema.title,
    ...uiSchema.options,
  };

  return (
    <Container>
      <Row className="center-sm space-between">
        <Col className="col-sm-12 col-md-6">
          <Paragraph title={ownProps.title} size="2" />
          <TextareaControl
            placeholder={ownProps.placeholder}
            path={path}
            handleChange={handleChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

export const TextareaTester = (uiSchema: TUiSchemaParam) =>
  uiSchema.type === 'Control';
