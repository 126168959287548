import {
  createContext,
  type ReactNode,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

export interface ICategoryDialogsContext {
  shouldShowHowGetNinjasWorksDialog: boolean;
  shouldShowInteractionDialog: boolean;
  dialogHowGetNinjasWorksIsNotOpen: boolean;
  toggleDialog: (dialogType: TDialogType) => void;
}

type TDialogType = 'interaction' | 'howGetNinjasWorks';

interface ICategoryDialogsProviderProps {
  children: ReactNode;
}

export const CategoryDialogsContext = createContext(
  {} as ICategoryDialogsContext
);

export const CategoryDialogsProvider = ({
  children,
}: ICategoryDialogsProviderProps) => {
  const [
    shouldShowHowGetNinjasWorksDialog,
    setShouldShowHowGetNinjasWorksDialog,
  ] = useState(false);

  const [shouldShowInteractionDialog, setShouldShowInteractionDialog] =
    useState(false);

  const dialogHowGetNinjasWorksIsNotOpen = !shouldShowHowGetNinjasWorksDialog;

  const toggleDialog = useCallback((dialogType: TDialogType) => {
    if (dialogType === 'interaction') {
      setShouldShowInteractionDialog(
        (oldShouldShowInteractionDialogValue) =>
          !oldShouldShowInteractionDialogValue
      );

      return;
    }

    if (dialogType === 'howGetNinjasWorks') {
      setShouldShowHowGetNinjasWorksDialog(
        (oldShouldShowHowGetNinjasWorksDialogValue) =>
          !oldShouldShowHowGetNinjasWorksDialogValue
      );

      return;
    }
  }, []);

  useEffect(() => {
    function observingDialogs() {
      const allDialogIsOpen =
        shouldShowHowGetNinjasWorksDialog && shouldShowInteractionDialog;

      if (allDialogIsOpen) {
        setShouldShowInteractionDialog(false);
      }
    }

    observingDialogs();
  }, [shouldShowHowGetNinjasWorksDialog, shouldShowInteractionDialog]);

  return (
    <CategoryDialogsContext.Provider
      value={{
        shouldShowHowGetNinjasWorksDialog,
        shouldShowInteractionDialog,
        dialogHowGetNinjasWorksIsNotOpen,
        toggleDialog,
      }}
    >
      {children}
    </CategoryDialogsContext.Provider>
  );
};

export function useCategoryDialogs() {
  return useContext(CategoryDialogsContext);
}
