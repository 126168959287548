import { useRef } from 'react';

type TDebounceFn = (...args: unknown[]) => void;

export function useDebounce(fn: TDebounceFn, delay: number) {
  const timeoutRef = useRef(null);

  function debounceFn(...args: unknown[]) {
    window.clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      fn(...args);
    }, delay);
  }

  return debounceFn;
}
