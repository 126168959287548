import { v4 as uuidv4 } from 'uuid';

import { type Method } from 'axios';

import { createQuestionSchema } from '../modules/survey/questionSchema';

import {
  type IQuestion,
  type ISurveySchema,
  type TElement,
} from '../modules/survey/@interfaces';

interface ISurveyExperienceResponse {
  data: {
    id: number;
    related_questions: {
      questions: IQuestion[];
    };
  };
}

type IHttpClientParams = {
  url: string;
  method: Method;
  headers: Record<string, string>;
};

export type THttpClient = ({
  url,
  method,
}: IHttpClientParams) => Promise<ISurveyExperienceResponse>;

export const experienceRatingService = (httpClient: THttpClient) => {
  return async (): Promise<ISurveySchema> => {
    const requestId = uuidv4();

    const headers = {
      'x-request-id': requestId,
    };

    const { data } = await httpClient({
      url: '/api/survey/experience',
      method: 'GET',
      headers,
    });

    const schema: ISurveySchema = data.related_questions.questions.reduce(
      (acc, question) => {
        createQuestionSchema(acc, question);

        const questionUiSchema = {
          type: 'Group',
          id: `${question.name}`,
          elements: [],
        };

        const questionElement: TElement = {
          scope: `#/properties/${question.name}`,
          options: {},
        };

        if (
          question.question_type === 'multiple_choice' &&
          question.options.max
        ) {
          questionElement.type = 'Tags';
        }

        if (question.question_type === 'text') {
          questionElement.type = 'Control';
        }

        if (
          question.question_type === 'text' &&
          question.options.field_type === 'multiple'
        ) {
          questionElement.type = 'Control';
        }

        if (question.options.placeholder) {
          questionElement.options.placeholder = question.options.placeholder;
        }

        questionUiSchema.elements =
          questionUiSchema.elements.concat(questionElement);

        acc.uiSchema.elements = acc.uiSchema.elements.concat(questionUiSchema);

        return acc;
      },
      {
        id: 0,
        schema: {
          type: 'object',
          properties: {},
          required: [],
        },
        uiSchema: {
          type: 'Steps',
          elements: [],
        },
      }
    );

    schema.id = data.id;

    return schema;
  };
};
