import classNames from 'classnames';
import { Avatar as DarriusAvatar } from 'darrius';

import { avatarFallback } from '../../../utils/form/utils/avatarFallback';

import {
  type CardAvatarProps,
  type CardBadgeProps,
  type CardProps,
  type CardRootProps,
} from './@interfaces';

import styles from './Card.module.scss';

// TODO: Adicionar possibilidade de escolher o filho a passar (as)
const Root = ({ isSelected, onClick, id, children }: CardRootProps) => {
  const anchorCardClassname = classNames(styles.card, {
    [styles['card--selected']]: isSelected,
  });

  return (
    <li className={styles.item}>
      <a className={anchorCardClassname} href={`/${id}`} onClick={onClick}>
        {children}
      </a>
    </li>
  );
};

const Content = ({ children }: CardProps) => (
  <div className={styles.card__content}>{children}</div>
);

const Avatar = ({ image }: CardAvatarProps) => {
  return (
    <DarriusAvatar
      alt={image}
      src={avatarFallback(image)}
      className={styles.card__picture}
      size={60}
      fallback={{
        variant: 'byName',
        name: image,
      }}
    />
  );
};

const Title = ({ children }: CardProps) => (
  <h3 className={styles.card__title}>{children}</h3>
);

const Subtitle = ({ children }: CardProps) => (
  <h4 className={styles.card__subtitle}>{children}</h4>
);

const Text = ({ children }: CardProps) => (
  <p className={styles.card__body}>{children}</p>
);

const Sidebar = ({ children }: CardProps) => (
  <div className={styles.card__sidebar}>{children}</div>
);

const Badge = ({ count }: CardBadgeProps) => (
  <p className={styles.card__badge}>{count}</p>
);

const ReadIcon = () => (
  <img
    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMDAwMDEgMTYuMTdMNS41MzAwMSAxMi43QzUuMTQwMDIgMTIuMzEgNC41MTAwMSAxMi4zMSA0LjEyMDAxIDEyLjdDMy43MzAwMSAxMy4wOSAzLjczMDAxIDEzLjcyIDQuMTIwMDEgMTQuMTFMOC4zMDAwMSAxOC4yOUM4LjY5MDAxIDE4LjY4IDkuMzIwMDEgMTguNjggOS43MTAwMSAxOC4yOUwyMC4yOSA3LjcxMDAxQzIwLjY4IDcuMzIwMDEgMjAuNjggNi42OTAwMSAyMC4yOSA2LjMwMDAxQzE5LjkgNS45MTAwMSAxOS4yNyA1LjkxMDAxIDE4Ljg4IDYuMzAwMDFMOS4wMDAwMSAxNi4xN1oiIGZpbGw9IiM4MTkyOUYiLz4KPC9zdmc+Cg=="
    alt="Ícone indicando que as mensagens desta conversa estão lidas"
  />
);

export const Card = {
  Root,
  Content,
  Avatar,
  Title,
  Subtitle,
  Text,
  Sidebar,
  Badge,
  ReadIcon,
};
