import { Container } from 'darrius';

import { HeadContent, Header, Toast } from '../../components';

import { SignInPin } from '../SignIn/SignInPin/SignInPin';

import { type IAuthProps } from '../../pages/requests/[uuid]/auth/user/[userId]/phone/[phoneId]';

export const AuthTemplate = (props: IAuthProps) => {
  const { uuid, i18n, urls } = props;

  return (
    <Container>
      <HeadContent
        metaTitle="GetNinjas | Validação de SMS"
        metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
        pageType="auth:show"
        noindex
      />

      <Header.Default onlyLogo />

      <SignInPin i18n={i18n} path={`/requests/${uuid}`} urls={urls} />

      <Toast />
    </Container>
  );
};
