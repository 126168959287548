import Link from 'next/link';

import { MdArrowBack } from 'react-icons/md';

import styles from './OrderBackArrow.module.scss';

interface IOrderBackArrowPropsWithTo {
  onClick?: never;
  to?: string;
}

interface IOrderBackArrowPropsWithOnClick {
  onClick: () => void;
  to?: never;
}

type TOrderBackArrowProps =
  | IOrderBackArrowPropsWithTo
  | IOrderBackArrowPropsWithOnClick;

const BACK_TO_ALL_ORDERS_TEXT = 'Voltar para Meus Pedidos';
const BACK_TO_ORDER = 'Voltar para o pedido';

export function OrderBackArrow({ onClick, to }: TOrderBackArrowProps) {
  if (!!to) {
    return (
      <Link
        href={to}
        className={styles.arrow_back}
        aria-label={BACK_TO_ALL_ORDERS_TEXT}
        title={BACK_TO_ALL_ORDERS_TEXT}
      >
        <MdArrowBack size={24} color="#425063" />
        <span>{BACK_TO_ALL_ORDERS_TEXT}</span>
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={styles.arrow_back}
      onClick={onClick}
      aria-label={BACK_TO_ORDER}
      title={BACK_TO_ORDER}
    >
      <MdArrowBack size={24} color="#425063" />
      <span>{BACK_TO_ORDER}</span>
    </button>
  );
}
