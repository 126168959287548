interface IIsWebviewParams {
  userAgent: string;
  standalone?: boolean;
  platform?: string;
  maxTouchPoints?: number;
}
export const isWebview = ({
  userAgent,
  standalone,
  platform,
  maxTouchPoints,
}: IIsWebviewParams) => {
  const normalizedUserAgent = userAgent.toLowerCase();

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) ||
    (platform === 'MacIntel' && maxTouchPoints > 1);
  const isAndroid = /android/.test(normalizedUserAgent);
  const isSafari = /safari/.test(normalizedUserAgent);
  const isInWebview =
    (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
    (isIos && !standalone && !isSafari);

  return isInWebview;
};
