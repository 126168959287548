import styles from './Shell.module.scss';

interface IShellProps {
  children: React.ReactNode;
}

export const Shell = ({ children }: IShellProps) => (
  <div className={styles.shell}>{children}</div>
);

interface IShellHeaderProps {
  children: React.ReactNode;
}

export const ShellHeader = ({ children }: IShellHeaderProps) => (
  <div className={styles.shell__header}>{children}</div>
);

interface IShellContentProps {
  children: React.ReactNode;
}

export const ShellContent = ({ children }: IShellContentProps) => (
  <div className={styles.shell__content}>{children}</div>
);

interface IShellFooterProps {
  children: React.ReactNode;
}

export const ShellFooter = ({ children }: IShellFooterProps) => (
  <div className={styles.shell__footer}>{children}</div>
);
