import dynamic from 'next/dynamic';
import Image from 'next/image';

import { Container, Typography } from 'darrius';

import { ProCardDetail } from '../../../../../../components';

import { imgPrefix } from '../../../../../../utils';

import styles from './FoundProfessionalsList.module.scss';

import { type TOrderDetailLeads } from '../../../../../../modules/orderDetails/transformations/@interfaces';

const OrderRatingCard = dynamic(
  () =>
    import('../OrderRatingCard/OrderRatingCard').then(
      (mod) => mod.OrderRatingCard
    ),
  {
    ssr: false,
  }
);

interface IFoundProfessionalsListProps {
  reviewUrl: string | null;
  leads: TOrderDetailLeads[];
}

export function FoundProfessionalsList({
  reviewUrl,
  leads,
}: IFoundProfessionalsListProps) {
  return (
    <Container className={styles.container}>
      <div className={styles.content}>
        {!!reviewUrl && <OrderRatingCard link={reviewUrl} />}

        <div className={styles.content__title}>
          <Image
            src={imgPrefix('/images/orders/pro.svg')}
            alt=""
            width={48}
            height={48}
          />

          <Typography.Subtitle as="h5" size="l">
            Profissionais encontrados
          </Typography.Subtitle>
        </div>

        <ul className={styles.list}>
          {leads.map((lead, index) => {
            if (lead.isPartnerProfile) {
              return (
                <li key={lead.id}>
                  <ProCardDetail.Institutional
                    id={lead.id}
                    isVerifiedPro={lead.documentationApproved}
                    link={lead.link}
                    name={lead.name}
                    photo={lead.avatar}
                    servicePrice={lead.price}
                    rating={{
                      count: lead.reviews.count,
                      value: lead.reviews.average,
                    }}
                  />
                </li>
              );
            } else {
              return (
                <li key={lead.id}>
                  <ProCardDetail.Default
                    id={lead.id}
                    index={index}
                    link={lead.link}
                    name={lead.name}
                    photo={lead.avatar}
                    isVerifiedPro={lead.documentationApproved}
                    rating={{
                      count: lead.reviews.count,
                      value: lead.reviews.average,
                    }}
                  />
                </li>
              );
            }
          })}
        </ul>
      </div>
    </Container>
  );
}
