import { useState } from 'react';

import axios from 'axios';

import { showToast } from '../../components/atoms/Toast/Toast';

import { type TPinValidateParams } from '../../pages/api/auth/pin/validate';

type TOnSubmitPinParams = Pick<TPinValidateParams, 'pin'>;

interface IValidatePinResponse {
  token: string;
}

const HEADERS = {
  Accept: 'application/hal+json',
  'Accept-Version': 7,
  'Content-Type': 'application/json',
  'Accept-Language': 'pt-BR',
};

export function useAuth(validatePinUrl: string, resendPinUrl: string) {
  const [isShouldResetCountdown, setIsShouldResetCountdown] = useState(false);
  const [isCountdownOver, setIsCountdownOver] = useState(false);

  function onResetCountdown() {
    setIsShouldResetCountdown(false);
  }

  function onCountdownOver() {
    setIsCountdownOver(true);
  }

  async function onSubmitPin({ pin }: TOnSubmitPinParams) {
    try {
      const { data } = await axios.post<IValidatePinResponse>(
        validatePinUrl,
        { pin },
        {
          headers: HEADERS,
        }
      );

      await axios.post(`${process.env.NEXT_PUBLIC_API_CLIENTS}/users`, null, {
        headers: {
          Authorization: data.token,
        },
      });

      showToast({
        message: 'Telefone validado com sucesso, você será redirecionado...',
        type: 'success',
        id: 'pin-success',
      });

      return {
        success: true,
      };
    } catch (error) {
      if (error.response.status === 422) {
        showToast({
          message: error.response.data.message,
          type: 'warning',
          id: 'pin-warning',
        });

        return {
          success: false,
        };
      }

      showToast({
        message: error.response.data.message,
        type: 'error',
        id: 'pin-error',
      });

      return {
        success: false,
      };
    }
  }

  async function onResendPin() {
    try {
      setIsShouldResetCountdown(true);
      setIsCountdownOver(false);

      await axios.post(
        resendPinUrl,
        {},
        {
          headers: HEADERS,
        }
      );

      showToast({
        message: 'PIN reenviado com sucesso',
        type: 'success',
        id: 'resend-pin-success',
      });
    } catch (error) {
      if (error.response.status === 422) {
        showToast({
          message: error.response.data.message,
          type: 'warning',
          id: 'resend-pin-warning',
        });
      }
    }
  }

  return {
    onSubmitPin,
    onResendPin,
    countDown: {
      onResetCountdown,
      onCountdownOver,
      isShouldResetCountdown,
      isCountdownOver,
    },
  };
}
