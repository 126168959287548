import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Paragraph, Chips, Container, Row, Col } from 'darrius';

import { useForm } from '../../hooks/useForm';

import styles from './Tags.module.scss';

import { type TUiSchemaParam } from '../@interfaces';
import { type ITagsProps } from './@interfaces';

export function Tags({ schema, uiSchema, path, handleChange }: ITagsProps) {
  const { form } = useForm();

  const [tagsValues, setTagsValues] = useState([]);

  const mapAnyOfToOptionsMapper = useMemo(
    () =>
      schema.anyOf.map(({ const: constant, title }) => ({
        label: title,
        value: constant,
      })),
    [schema.anyOf]
  );

  const ownProps = {
    title: schema.title,
    description: schema.description,
    options: mapAnyOfToOptionsMapper,
    maxItems: schema.maxItems,
    ...uiSchema.options,
  };

  useEffect(() => {
    if (form[path]) {
      setTagsValues(form[path]);
    }
  }, [form, path]);

  const isReachedTagLimit = useCallback(() => {
    if (schema.maxItems) {
      if (tagsValues.length === schema.maxItems) {
        return true;
      }
    }

    return false;
  }, [schema.maxItems, tagsValues.length]);

  const removeTag = useCallback(
    (tagName: string) => {
      const tagsValuesArray = [...tagsValues];
      const removeTagIndex = tagsValuesArray.indexOf(tagName);

      tagsValuesArray.splice(removeTagIndex, 1);

      setTagsValues(tagsValuesArray);

      handleChange(path, tagsValuesArray);
    },
    [handleChange, path, tagsValues]
  );

  const addTag = useCallback(
    (tagName: string) => {
      setTagsValues((oldAdvantagesState) => [...oldAdvantagesState, tagName]);

      handleChange(path, [...tagsValues, tagName]);
    },
    [handleChange, path, tagsValues]
  );

  const handleChangeChips = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const tagNameValue = evt.target.value;
      const checkIfValueIsInTagsArrayState = tagsValues.includes(tagNameValue);

      if (checkIfValueIsInTagsArrayState) {
        removeTag(tagNameValue);

        return;
      }

      addTag(tagNameValue);
    },
    [addTag, removeTag, tagsValues]
  );

  return (
    <Container>
      <Row className="center-sm space-between">
        <Col className="col-sm-12 col-md-8">
          <Paragraph
            title={ownProps.title}
            text={ownProps.description}
            size="2"
          />
          <ul className={styles.tags__list}>
            {ownProps.options.map((option) => {
              const isDefaultChecked = tagsValues.includes(option.label);
              const isDisabled = isReachedTagLimit() && !isDefaultChecked;

              return (
                <li className={styles.tags__item} key={option.value}>
                  <Chips
                    id={option.value}
                    label={option.label}
                    name={option.label}
                    value={option.label}
                    onChange={handleChangeChips}
                    defaultChecked={isDefaultChecked}
                    disabled={isDisabled}
                    multiple
                  />
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export const TagsTester = (uiSchema: TUiSchemaParam) =>
  uiSchema.type === 'Tags';
