import { type ReactNode, createContext, useCallback, useState } from 'react';

export type TTemplate =
  | 'accepted'
  | 'not-answered'
  | 'refused'
  | 'finished'
  | 'initialTemplate';

interface ITemplateContext {
  handleTemplateChange: (templateName: TTemplate) => void;
  template: string;
}

interface ITemplateProviderProps {
  children: ReactNode;
}

export const TemplateContext = createContext({} as ITemplateContext);

export function TemplateProvider({ children }: ITemplateProviderProps) {
  const [template, setTemplate] = useState<TTemplate>('initialTemplate');

  const handleTemplateChange = useCallback((templateName: TTemplate) => {
    setTemplate(templateName);
  }, []);

  return (
    <TemplateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        handleTemplateChange,
        template,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
}
