import { Typography } from 'darrius';
import styles from './OrderCategoryTitle.module.scss';

import { type TTypographyTag } from 'darrius/build/types/components/atoms/Typography/Typography';

type TCategoryDefault = {
  text: string;
  as?: TTypographyTag | 'span' | 'p';
};

interface IOrderCategoryTitleProps {
  rootCategory: TCategoryDefault;
  category: TCategoryDefault;
}

export function OrderCategoryTitle({
  category,
  rootCategory,
}: IOrderCategoryTitleProps) {
  const { text: rootCategoryText, as: asRootCategory = 'h3' } = rootCategory;
  const { text: categoryText, as: asCategory = 'span' } = category;

  return (
    <div>
      <Typography.Subtitle
        as={asRootCategory as TTypographyTag}
        className={styles.order__category}
      >
        {rootCategoryText}
      </Typography.Subtitle>
      <Typography.Text as={asCategory} className={styles.order__category_root}>
        {categoryText}
      </Typography.Text>
    </div>
  );
}
