import Image from 'next/image';
import Link from 'next/link';

import { Button, Container, Typography } from 'darrius';

import { MdHelpOutline } from 'react-icons/md';

import { imgPrefix } from '../../../../../../utils';

import styles from './OrderStatusAlert.module.scss';

import { type TStatus } from '../../../../../../components/atoms/Status/Status';

interface IOrderStatusAlertProps {
  status: Extract<TStatus, 'blocked' | 'finalized_zero_lead'>;
}

export function OrderStatusAlert({ status }: IOrderStatusAlertProps) {
  const title = {
    blocked: 'Seu pedido violou nossas regras',
    finalized_zero_lead: 'Não encontramos profissionais',
  };

  const button = {
    blocked: {
      text: 'Conferir regras',
      link: '/meus-pedidos/pedido-bloqueado',
    },
    finalized_zero_lead: {
      text: 'Entenda o que houve',
      link: '/meus-pedidos/profissionais-nao-encontrados',
    },
  };

  return (
    <Container className={styles.container}>
      <Image
        src={imgPrefix('/images/orders/warning.svg')}
        alt=""
        width={72}
        height={72}
        quality={100}
      />

      <Typography.Subtitle as="h5" size="l" className={styles.description}>
        {title[status]}
      </Typography.Subtitle>

      <Link href={button[status].link} passHref legacyBehavior>
        <Button.Link>
          <MdHelpOutline size={20} color="#fff" />
          {button[status].text}
        </Button.Link>
      </Link>
    </Container>
  );
}
