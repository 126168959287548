import { Button } from 'darrius';

import { Dialog } from '../../../../components';

import styles from './FaqDeleteAccountDialogTemplate.module.scss';

import { type FormEvent, useState } from 'react';
import { type IChat } from '../../../../locales/@interfaces/faqDeleteAccount';

interface IFaqChatProps {
  chat: IChat;
}

export default function FaqDeleteAccountDialogTemplate(props: IFaqChatProps) {
  const { chat } = props;

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChange = () => {
    setIsButtonDisabled(false);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    setIsButtonDisabled(true);
    event.preventDefault();
    const accountType = (
      (event.target as HTMLFormElement).querySelector(
        'input:checked'
      ) as HTMLInputElement
    ).value;
    location.href = chat.accounts[accountType];
  };

  return (
    <Dialog.Portal>
      <Dialog.Content className={styles.chat_dialog__content}>
        <Dialog.Title className={styles.chat_dialog__title}>
          {chat.title}
        </Dialog.Title>

        <Dialog.Description className={styles.chat_dialog__description}>
          {chat.description}
        </Dialog.Description>

        <form onSubmit={handleSubmit}>
          <ul>
            {chat.options.map((option) => (
              <li key={option.id} className={styles.form__radio}>
                <input
                  onChange={handleChange}
                  type="radio"
                  id={option.id}
                  name={option.name}
                  value={option.value}
                />
                <label htmlFor={option.id}>{option.description}</label>
              </li>
            ))}
          </ul>
          <Button.Root
            type="submit"
            fullWidth
            variation="danger"
            disabled={isButtonDisabled}
          >
            {chat.ctaText}
          </Button.Root>
        </form>
      </Dialog.Content>
    </Dialog.Portal>
  );
}
