import {
  type ChangeEvent,
  type FormEvent,
  type KeyboardEventHandler,
  type MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Card,
  CardBody,
  Paragraph,
  Row,
  Col,
  TextField,
  Button,
} from 'darrius';

import { onlyNumbers, phoneMask } from '../../utils/formUtils';
import { axiosInstance } from '../../utils/axiosInstance';

import { notifyErrorClient } from '../../services/notifyError/client';
import { errorPrefix } from '../../utils/errorPrefix';

import styles from './HomeTemplate.module.scss';

interface IHomeRegisterProps {
  data: {
    title: string;
    subtitle: string;
    phone: string;
    email: string;
    invalidFieldText: string;
    emptyFieldText: string;
    errorText: string;
    buttonText: string;
  };
}

interface ISignUpApiResponse {
  redirectUrl: string;
}

interface IError {
  email: string;
  phone: string;
}

const HomeRegister = ({ data }: IHomeRegisterProps) => {
  const {
    title,
    subtitle,
    phone,
    email,
    invalidFieldText,
    emptyFieldText,
    errorText,
    buttonText,
  } = data;

  const patterns = useMemo(() => {
    return {
      email: '[a-zA-Z0-9._%+-]+@[a-z0-9]+\\.[a-z]+.([a-z]+)?',
      phone: '[0-9]{2} [0-9]{5}-[0-9]{4}',
    };
  }, []);

  const [form, setForm] = useState({ email: '', phone: '' });
  const [errors, setErrors] = useState<IError>({} as IError);

  const validate = useCallback(
    (fieldNames: string[]) => {
      const errorsForm = {} as IError;

      fieldNames.forEach((fieldName) => {
        if (form[fieldName] === '') {
          errorsForm[fieldName] = emptyFieldText;
          return;
        }

        if (
          form[fieldName] !== '' &&
          !new RegExp(patterns[fieldName]).test(form[fieldName])
        ) {
          errorsForm[fieldName] = invalidFieldText;
        }
      });

      setErrors(errorsForm);
    },
    [emptyFieldText, form, invalidFieldText, patterns]
  );

  useEffect(() => {
    const fields = ['email', 'phone'];

    if (form.email !== '' && form.phone !== '') {
      validate(fields);
    }
  }, [form.email, form.phone, validate]);

  function handleFormChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const newFormState = {
      ...form,
      [name]: value,
    };

    if (name === 'phone') {
      event.target.value = phoneMask(value);
      newFormState[name] = phoneMask(value);
    }

    validate([name]);
    setForm(newFormState);
  }

  async function handleSubmitForm(event: FormEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (
      Object.keys(errors).length === 0 &&
      form.email.length &&
      form.phone.length
    ) {
      form.phone = onlyNumbers(form.phone);
      const headers = {
        'Accept-Language': 'pt-BR',
        'Content-Type': 'application/json',
        Accept: '*/*',
      };

      const requestOptions = {
        headers,
        url: '/api/signup',
        method: 'POST',
        gzip: false,
        data: form,
      } as const;

      try {
        const result = await axiosInstance<ISignUpApiResponse>(requestOptions);
        const { redirectUrl } = result.data;
        window.location.href = redirectUrl;
      } catch (error) {
        notifyErrorClient(errorPrefix('[Home-Register]', error));

        alert(errorText);
      }
    }

    return false;
  }

  return (
    <Card className={`${styles['register-as-pro']} register-as-pro`}>
      <CardBody>
        <Paragraph
          className="no-margin"
          title={title}
          text={subtitle}
          size="2"
        />

        <form onSubmit={handleSubmitForm} name="register-form" method="post">
          <Row className="space-between start-sm top-sm">
            <Col className="col-sm-12 col-md-4">
              <TextField
                id="email"
                name="email"
                type="email"
                label={email}
                pattern={patterns.email}
                placeholder="Ex.: email@email.com.br"
                onChange={handleFormChange}
                onClick={
                  handleFormChange as unknown as MouseEventHandler<HTMLInputElement>
                }
                onBlur={handleFormChange}
                className={errors.email && 'error'}
                errorMessage={errors.email}
              />
            </Col>

            <Col className="col-sm-12 col-md-4">
              <TextField
                id="phone"
                name="phone"
                type="tel"
                label={phone}
                pattern={patterns.phone}
                placeholder="Ex.: 11 99999-9999"
                onChange={handleFormChange}
                onKeyDown={
                  handleFormChange as unknown as KeyboardEventHandler<HTMLInputElement>
                }
                onBlur={handleFormChange}
                className={errors.phone && 'error'}
                errorMessage={errors.phone}
                maxLength={14}
              />
            </Col>

            <Col className="col-sm-12 col-md-4">
              <Button.Root className="no-wrap" type="submit" fullWidth>
                {buttonText}
              </Button.Root>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

export default HomeRegister;
