import { memo, useCallback, useEffect, useState } from 'react';

import { CurrencyInput } from 'darrius';

import { useDebounce } from '../../../../../../hooks/useDebounce';
import { useForm } from '../../../../hooks/useForm';

import { type IInputControlProps } from './@interfaces';

export function InputControlComponent({
  placeholder,
  path,
  handleChange,
}: IInputControlProps) {
  const debounceChange = useDebounce(handleChange, 500);

  const { form } = useForm();

  const [currencyValue, setCurrencyValue] = useState(0);
  const [previousCurrencyValue, setPreviousCurrencyValue] = useState(-1);

  useEffect(() => {
    function setInitialCurrencyValue() {
      if (form[path] && previousCurrencyValue === -1) {
        const currencyValueToInt = parseInt(
          String(form[path]).replace(/\D/g, ''),
          10
        );

        setCurrencyValue(currencyValueToInt);
        setPreviousCurrencyValue(currencyValueToInt);
      }
    }

    setInitialCurrencyValue();
  }, [currencyValue, form, path, previousCurrencyValue]);

  const onChange = useCallback(
    (formattedValue: number) => {
      const currencyValueFormatted = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(formattedValue / 100);

      setCurrencyValue(formattedValue);

      debounceChange(path, currencyValueFormatted);
    },
    [debounceChange, path]
  );

  return (
    <CurrencyInput
      placeholder={placeholder}
      onValueChange={onChange}
      value={currencyValue}
      currency="BRL"
      locale="pt-BR"
    />
  );
}

export const InputControl = memo(InputControlComponent);
