import { Paragraph, Container, Col, Row } from 'darrius';

import { InputControl } from './components/InputControl';

import { type ISurveyInputProps } from './@interfaces';
import { type TUiSchemaParam } from '../@interfaces';

export function Input({
  schema,
  uiSchema,
  path,
  handleChange,
}: ISurveyInputProps) {
  const ownProps = {
    title: schema.title,
    description: schema.description,
    ...uiSchema.options,
  };

  const inputPlaceholder = ownProps.placeholder || '';

  return (
    <Container>
      <Row className="center-sm space-between">
        <Col className="col-sm-12 col-md-6">
          <Paragraph title={ownProps.title} text={ownProps.description} />
          <InputControl
            placeholder={inputPlaceholder}
            handleChange={handleChange}
            path={path}
          />
        </Col>
      </Row>
    </Container>
  );
}

export const InputTester = (uiSchema: TUiSchemaParam) =>
  uiSchema.type === 'Input';
