import Link from 'next/link';
import Image from 'next/image';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Typography,
} from 'darrius';

import { imgPrefix } from '../../../../../../utils';

import styles from './OrderRatingCard.module.scss';

interface IOrderRatingCardProps {
  link: string;
}

export function OrderRatingCard({ link }: IOrderRatingCardProps) {
  return (
    <Container className={styles.container}>
      <Card>
        <CardHeader className={styles.rate__header}>
          <div>
            <Image
              src={imgPrefix('/images/orders/star.svg')}
              alt=""
              height={40}
              width={40}
              quality={100}
            />

            <Typography.Subtitle as="h5" size="l">
              Avalie seu pedido
            </Typography.Subtitle>
          </div>
        </CardHeader>

        <CardBody className={styles.rate__body}>
          <Typography.Text as="p" className={styles.rate__body_description}>
            Como foi o seu pedido? Conta pra gente!
          </Typography.Text>

          <Link href={link} passHref legacyBehavior>
            <Button.Link>Avaliar pedido</Button.Link>
          </Link>
        </CardBody>
      </Card>
    </Container>
  );
}
