export function onlyNumbers(value: string): string {
  return value.replace(/\D/g, '');
}

export function phoneMask(phoneNumber: string): string {
  const numbers = onlyNumbers(phoneNumber);
  const chars = numbers.length;
  const ddd = chars > 2 ? `${numbers.slice(0, 2)} ` : numbers.slice(0, 2);
  const firstPart = `${numbers.slice(2, 7)}`;
  const lastPart = `-${numbers.slice(7, 11)}`;
  const fullMask = ddd + firstPart + lastPart;

  if (chars <= 7) {
    return ddd + firstPart;
  }

  return fullMask;
}

export function cpfMask(cpfNumber: string) {
  const numbers = onlyNumbers(cpfNumber);
  if (!numbers) return '';

  if (numbers.length > 11) {

    return cpfNumber.slice(0, -1);

  } else {
    const firstPart = numbers.match(/.{1,3}/g);

    if (firstPart) {
      const lastPart = firstPart.join('.');
      const fullMask = lastPart.replace(/\.(?!\d{3})/g, '-');

      return fullMask;
    }
    return numbers;
  }
}
