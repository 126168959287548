import states from '../data/shared/states.json';
import cities from '../data/shared/cities.json';

interface ILocation {
  uf?: string;
  name?: string;
}

// Busca preposição correta de acordo com a cidade ou estado
export const getPrepositionOf = (
  typeOfLocation = 'none',
  location: ILocation = { uf: '', name: '' }
): string => {
  let preposition: string;

  switch (typeOfLocation) {
    case 'state':
      preposition = states.prepositionN[location.uf];
      break;
    case 'city':
      preposition = cities.preposition[location.name] || 'em';
      break;
    default:
      preposition = 'em';
  }

  return preposition;
};
