import Image from 'next/image';
import { Row, Col, Paragraph, Button } from 'darrius';

import { getBlurDataURL, imgPrefix } from '../../../utils';

import styles from './ProsNotFound.module.scss';

export const ProsNotFound = () => {
  return (
    <Row className={`${styles['pros-not-found']} center-sm`}>
      <Col
        className={`${styles['pros-not-found__pros-not-found']} col-sm-12 col-md-6 center-sm`}
      >
        <Row className={`center-sm`}>
          <Col
            className={`${styles['pros-not-found__img-not-found']} col-sm-12 center-sm`}
          >
            <Image
              src={imgPrefix(
                '/images/contato-instantaneo/img_instant-matching-not-found.svg'
              )}
              width="106"
              height="106"
              alt="Lupa"
              placeholder="blur"
              blurDataURL={getBlurDataURL()}
              priority
            />
          </Col>

          <Col className="col-sm-10">
            <Paragraph
              title="Ainda não encontramos o profissional ideal"
              text="Mas não se preocupe, enviamos o seu pedido e em breve até 4 profissionais poderão entrar em contato com você."
              size="3"
            />
          </Col>
        </Row>

        <Row
          className={`center-sm hide-md ${styles['pros-not-found__button']}`}
        >
          <Button.Link href="https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=ci_web&utm_medium=getninjas&utm_campaign=sem_pro_listagem">
            Baixe o app e acompanhe o pedido
          </Button.Link>
        </Row>
      </Col>

      <Col className={`col-md-6 center-sm hide-sm`}>
        <Row
          className={`${styles['pros-not-found__qrcode-section']} center-sm`}
        >
          <Col className="col-sm-12">
            <Paragraph text="Abra sua câmera e escaneie." headline size="3" />
          </Col>

          <Col
            className={`col-sm-12 ${styles['pros-not-found__image-qrcode']}`}
          >
            <Image
              src={imgPrefix(
                '/images/contato-instantaneo/qrcode-pros-not-found-desktop.svg'
              )}
              alt="QRCode Baixe o app"
              width={241}
              height={241}
              title="QRCode Baixe o app"
              placeholder="blur"
              blurDataURL={getBlurDataURL()}
              priority
            />

            <Paragraph
              text="<strong>Baixe o app</strong> e converse com o profissional pelo chat."
              size="1"
              headline
            />
          </Col>

          <Col className="col-sm-12 hero-section__image-qrcode">
            <Image
              src={imgPrefix(
                '/images/contato-instantaneo/img_instant-matching-stores.svg'
              )}
              alt="Baixe o app"
              width={193.49}
              height={40}
              title="Baixe o app"
              placeholder="blur"
              blurDataURL={getBlurDataURL()}
              priority
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
