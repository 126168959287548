import { useMemo, memo, useEffect, useState } from 'react';

import { useForm } from '../../hooks/useForm';

import { get } from '../../utils/get';
import { last } from '../../utils/last';
import { splitPath } from '../../utils/splitPath';
import { checkComponentIsVisible } from '../../utils/checkComponentIsVisible';
import { shallowEqual } from '../../utils/shallowEqual';

import { type TSurveyDefaultProps } from '../@interfaces';

function TestAndRenderComponent({
  schema,
  uiSchema,
  dataSchema,
  renderers,
  onSubmit,
}: TSurveyDefaultProps) {
  const { form, handleChangeForm } = useForm();

  const [path, setPath] = useState('');
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (uiSchema.scope) {
      const lastSplitPath = String(last(splitPath(uiSchema.scope)));

      setPath(lastSplitPath);
    }
  }, [uiSchema.scope]);

  useEffect(() => {
    if (uiSchema.rule) {
      const componentIsVisible = checkComponentIsVisible(uiSchema.rule, form);

      setIsVisible(componentIsVisible);
    }
  }, [form, uiSchema.rule]);

  const currentDataSchema = useMemo(() => {
    if (uiSchema.options && uiSchema.options.values) {
      return get(dataSchema, splitPath(uiSchema.options.values));
    }

    return dataSchema;
  }, [dataSchema, uiSchema.options]);

  const currentSchema = useMemo(() => {
    if (uiSchema.scope) {
      return get(schema, splitPath(uiSchema.scope));
    }

    return schema;
  }, [schema, uiSchema.scope]);

  const componentToRender = useMemo(
    () => renderers.find((render) => render.tester(uiSchema)),
    [renderers, uiSchema]
  );

  const TestAndRendered = useMemo(() => {
    if (componentToRender) {
      return componentToRender.componentToRender;
    }

    return null;
  }, [componentToRender]);

  return isVisible && TestAndRendered ? (
    <TestAndRendered
      schema={currentSchema}
      uiSchema={uiSchema}
      dataSchema={currentDataSchema}
      renderers={renderers}
      path={path}
      handleChange={handleChangeForm}
      onSubmit={onSubmit}
    />
  ) : null;
}

export function testAndRenderPropsAreEquals(
  prevPropsTestAndRender: Readonly<TSurveyDefaultProps>,
  nextPropsTestAndRender: Readonly<TSurveyDefaultProps>
) {
  return (
    shallowEqual(
      prevPropsTestAndRender.schema,
      nextPropsTestAndRender.schema
    ) &&
    shallowEqual(
      prevPropsTestAndRender.uiSchema,
      nextPropsTestAndRender.uiSchema
    ) &&
    shallowEqual(
      prevPropsTestAndRender.dataSchema,
      nextPropsTestAndRender.dataSchema
    ) &&
    JSON.stringify(prevPropsTestAndRender.renderers) ===
      JSON.stringify(nextPropsTestAndRender.renderers) &&
    JSON.stringify(prevPropsTestAndRender.onSubmit) ===
      JSON.stringify(nextPropsTestAndRender.onSubmit)
  );
}

export const TestAndRender = memo(
  TestAndRenderComponent,
  testAndRenderPropsAreEquals
);
