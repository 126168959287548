import { v4 as uuidv4 } from 'uuid';

import { type Method } from 'axios';

import serviceRatingUi from '../data/survey/serviceRatingUi.json';
import {
  type IQuestion,
  type ISurveySchema,
  type TElement,
  type TElementGroup,
} from '../modules/survey/@interfaces';
import { createQuestionSchema } from '../modules/survey/questionSchema';

type IHttpClientParams = {
  url: string;
  method: Method;
  headers: Record<string, string>;
};

type THttpClient = ({ url, method }: IHttpClientParams) => Promise<unknown>;

interface ILead {
  id: number;
  profileId: number;
  userName: string;
  userAvatar: string;
}

interface IProfilesResponse {
  data: {
    leads: ILead[];
    category: {
      name: string;
    };
  };
}

interface ISurveyServiceResult {
  data: {
    id: number;
    related_questions: {
      questions: IQuestion[];
    };
  };
}

export const serviceRatingService = (httpClient: THttpClient) => {
  return async (id: number): Promise<ISurveySchema> => {
    const requestId = uuidv4();

    const headers = {
      'x-request-id': requestId,
    };

    const professionalResult = (await httpClient({
      url: `/api/profiles?id=${id}`,
      method: 'GET',
      headers,
    })) as IProfilesResponse;

    const formResult = (await httpClient({
      url: `/api/survey/service`,
      method: 'GET',
      headers,
    })) as ISurveyServiceResult;

    const hydratedProfessionalData = {
      name: 'professional',
      title: 'Qual profissional você contratou?',
      question_type: 'single_choice',
      description: '',
      mandatory: true,
      options: {
        list: professionalResult.data.leads.map((lead) => ({
          id: lead.profileId,
          leadId: lead.id,
          name: lead.userName,
          image: lead.userAvatar,
          category: professionalResult.data.category.name,
        })),
      },
      range: null,
      questionOrder: 0,
    };

    const hydratedForm = formResult.data.related_questions.questions.reduce(
      (acc, question) => {
        let all = acc.concat(question);

        if (question.related_sub_questions) {
          all = all.concat(
            Object.values(question.related_sub_questions.sub_questions)
          );
        }

        return all;
      },
      []
    );

    const mergedData = [].concat(hydratedProfessionalData, hydratedForm);

    const createUiSchema = (
      acc: ISurveySchema,
      question: IQuestion,
      index: number
    ) => {
      const uiSchema = serviceRatingUi[index];

      const questionElement: TElement = {
        scope: `#/properties/${question.name}`,
        options: {},
      };

      if (uiSchema.dependency) {
        questionElement.dependency = { ...uiSchema.dependency };
      }

      if (uiSchema.options) {
        questionElement.options = { ...uiSchema.options };
      }

      if (uiSchema.rule) {
        questionElement.rule = { ...uiSchema.rule };
      }

      if (
        question.question_type === 'multiple_choice' &&
        question.options.max
      ) {
        questionElement.type = 'Tags';
      }

      if (question.question_type === 'single_choice') {
        questionElement.type = 'List';
      }

      if (question.question_type === 'text') {
        questionElement.type = 'Input';
      }

      if (
        question.question_type === 'text' &&
        question.options.field_type === 'multiple'
      ) {
        questionElement.type = 'Control';
      }

      if (question.question_type === 'rating') {
        questionElement.type = 'Rating';
      }

      if (question.options && question.options.mask) {
        questionElement.options.mask = question.options.mask;
      }

      if (question.options && question.options.placeholder) {
        questionElement.options.placeholder = question.options.placeholder;
      }

      if (!Object.keys(questionElement.options).length) {
        delete questionElement.options;
      }

      const currentGroup = acc.uiSchema.elements[uiSchema.group - 1];

      if (currentGroup) {
        currentGroup.elements = currentGroup.elements.concat(questionElement);
      } else {
        const questionUiSchema = {
          id: `group${uiSchema.group}`,
          type: 'Group',
          elements: [],
        } as TElementGroup;

        questionUiSchema.elements =
          questionUiSchema.elements.concat(questionElement);

        acc.uiSchema.elements = acc.uiSchema.elements.concat(questionUiSchema);
      }
    };

    const schema: ISurveySchema = mergedData.reduce(
      (acc, question, index) => {
        createQuestionSchema(acc, question);
        createUiSchema(acc, question, index);

        return acc;
      },
      {
        schema: {
          type: 'object',
          properties: {},
          required: [],
        },
        uiSchema: {
          type: 'Steps',
          elements: [],
        },
      }
    );

    schema.uiData = {
      professionals: hydratedProfessionalData.options.list,
    };

    schema.id = formResult.data.id;

    return schema;
  };
};
