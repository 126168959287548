import { Suspense, useState } from 'react';
import dynamic from 'next/dynamic';

import { Button, Col, Row } from 'darrius';

import { Dialog, DialogLoading } from '../../../../components';

import styles from './FaqChatCta.module.scss';

import { type TFaqChat } from '../../../../locales/@interfaces/faq';

interface IFaqChatCtaProps {
  chat: TFaqChat;
}

const FaqChatDialogTemplate = dynamic(
  () => import('../FaqChatDialogTemplate/FaqChatDialogTemplate'),
  {
    loading: () => <DialogLoading />,
    ssr: false,
  }
);

export function FaqChatCta(props: IFaqChatCtaProps) {
  const { chat } = props;

  const [isFaqChatDialogOpen, setIsFaqChatDialogOpen] = useState(false);

  function handleOpenFaqChatDialog() {
    setIsFaqChatDialogOpen((prevState) => !prevState);
  }

  return (
    <Dialog.Root
      onOpenChange={handleOpenFaqChatDialog}
      open={isFaqChatDialogOpen}
    >
      <section className={styles.chat}>
        <Row className={styles.chat__text}>
          <Col className="col-sm-12">
            <span>{chat.title}</span>
          </Col>
        </Row>

        <Row>
          <Col className="col-sm-12">
            <Dialog.Trigger asChild>
              <Button.Root size="small">
                <Button.Icon name="MdChat" />
                {chat.button.text}
              </Button.Root>
            </Dialog.Trigger>
          </Col>
        </Row>
      </section>

      {isFaqChatDialogOpen && (
        <Suspense fallback={<DialogLoading />}>
          <FaqChatDialogTemplate chat={chat} />
        </Suspense>
      )}
    </Dialog.Root>
  );
}
