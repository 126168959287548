import { Col, Container, Row, Typography } from 'darrius';

import { OrderBackArrow } from '../OrderDetailsSections';

import styles from './ReviewOrder.module.scss';

import { type TReviewData } from '../../../../../modules/orderDetails/pageProps/@interfaces';

interface IReviewOrderProps {
  onShowReviewOrder: () => void;
  reviewData: TReviewData[];
}

export function ReviewOrder({
  onShowReviewOrder,
  reviewData,
}: IReviewOrderProps) {
  return (
    <main className={styles.container}>
      <Container>
        <OrderBackArrow onClick={onShowReviewOrder} />
      </Container>

      <Container className={styles.content}>
        <Typography.Subtitle as="h1" size="l">
          Detalhe do pedido
        </Typography.Subtitle>

        <Row className="space-between">
          {reviewData.map((item) => (
            <Col
              key={item.id}
              className={`col-sm-12 col-md-12 ${styles.items}`}
            >
              <Typography.Subtitle as="h2" size="m">
                {item.title}
              </Typography.Subtitle>

              <Typography.Text as="p">{item.text}</Typography.Text>
            </Col>
          ))}
        </Row>
      </Container>
    </main>
  );
}
