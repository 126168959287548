import { Paragraph } from 'darrius';
import styles from '../ChatForConciergeTemplate.module.scss';
import { Loading } from '../../../components';
import { Card } from '../Card/Card';
import { truncate } from '../../../utils';
import { type IConversationsProps } from './@interfaces';
import { Alert } from '../Alert/Alert';

// TODO: Separar os estilos de conversations num arquivo scss
export const Conversations = ({
  state,
  conversations,
  conversationInfoMessage,
  conversationId,
  isNotificationEnabled,
  handleConversationClick,
  handleActivateNotifications,
  errorMessage,
}: IConversationsProps) => {
  return (
    <>
      <Paragraph size="2" title="Mensagens" />
      {state === 'success' &&
        !isNotificationEnabled &&
        conversations.length > 0 && (
          <section className={styles.notification}>
            <Alert.Root onClick={handleActivateNotifications}>
              <Alert.Icon
                image={`${process.env.NEXT_PUBLIC_ASSETS_PREFIX}/images/chat/message.svg`}
                description="Ícone de mensagem"
              />
              <Alert.Body>
                <Alert.Title>Ative as notificações</Alert.Title>
                <Alert.Text>
                  Receba notificações de mensagens no seu computador, para isso
                  ative as notificações na sua área de trabalho.
                </Alert.Text>
              </Alert.Body>
              <Alert.Link
                image={`${process.env.NEXT_PUBLIC_ASSETS_PREFIX}/images/chat/arrow-forward.svg`}
                description="Ative as notificações"
              />
            </Alert.Root>
          </section>
        )}
      <ul className={styles.list}>
        {conversations.length > 0 &&
          conversations.map((conversation) => (
            <Card.Root
              key={conversation.chatId}
              isSelected={conversationId === conversation.chatId}
              id={conversation.chatId}
              onClick={(event) => {
                event.preventDefault();
                handleConversationClick(conversation);
              }}
            >
              <Card.Avatar image={conversation.title} />
              <Card.Content>
                <Card.Title>{conversation.title}</Card.Title>
                <Card.Subtitle>{conversation.subtitle}</Card.Subtitle>
                <Card.Text>
                  {truncate(conversation.message, 80, '...')}
                </Card.Text>
              </Card.Content>
              {conversation.lastMessageSent && (
                <Card.Sidebar>
                  <Card.Text>{conversation.lastMessageSent}</Card.Text>
                  {conversation.isUnread ? (
                    <Card.Badge count={conversation.unreadMessages} />
                  ) : (
                    <Card.Text>
                      <Card.ReadIcon />
                    </Card.Text>
                  )}
                </Card.Sidebar>
              )}
            </Card.Root>
          ))}
        {conversationInfoMessage && (
          <p className={styles.message}>{conversationInfoMessage}</p>
        )}
        {state === 'loading' && (
          <div className={styles.message}>
            Carregando mensagens <Loading />
          </div>
        )}
        {state === 'error' && (
          <div className={styles.message}>
            {errorMessage ||
              'Tivemos um erro carregando a listagem. Por favor tente novamente mais tarde.'}
          </div>
        )}
      </ul>
    </>
  );
};
