import { Button, Col, Container, Paragraph, Row } from 'darrius';

import { useTemplate } from '../../utils/form/hooks/useTemplate';

import { HeadContent, Header } from '../../components';

import { Shell, ShellHeader, ShellContent } from './Shell';

export function SurveyDecision() {
  const { handleTemplateChange } = useTemplate();

  return (
    <>
      <HeadContent
        metaTitle="GetNinjas | Orçamento de Profissionais Confiáveis no GetNinjas.com.br"
        metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
        pageType="pages:survey/decision"
      />
      <Shell>
        <ShellHeader>
          <Container>
            <Header.Default onlyLogo />
          </Container>
        </ShellHeader>

        <ShellContent>
          <Container>
            <Row className="center-sm space-between">
              <Col className="col-sm-12">
                <Paragraph
                  size="2"
                  title="Você contratou um profissional pelo GetNinjas?"
                />
              </Col>
              <Col className="col-sm-12 col-md-4">
                <Button.Root
                  className="primary"
                  onClick={() => handleTemplateChange('accepted')}
                  fullWidth
                >
                  Sim
                </Button.Root>
              </Col>
              <Col className="col-sm-12 col-md-4">
                <Button.Root
                  className="secondary"
                  onClick={() => handleTemplateChange('refused')}
                  fullWidth
                >
                  Não
                </Button.Root>
              </Col>
            </Row>
          </Container>
        </ShellContent>
      </Shell>
    </>
  );
}
