import {
  Button,
  Col,
  Container,
  List,
  ListItem,
  Row,
  Typography,
} from 'darrius';
import { MdArrowBack } from 'react-icons/md';

import { HeadContent, Header } from '../../components';

import styles from './ProsNotFoundOrderTemplate.module.scss';
import { type IOrderProps } from '../../pages/meus-pedidos';

export function ProsNotFoundOrderTemplate({ i18n, userData }: IOrderProps) {
  return (
    <div className={styles['not-found-orders']}>
      <HeadContent
        metaTitle={i18n.prosNotFoundOrder.title}
        metaDescription={i18n.prosNotFoundOrder.description}
        pageType={i18n.prosNotFoundOrder.pageType}
        keywords={i18n.prosNotFoundOrder.keywords}
        noindex
      />

      <Header.Auth userData={userData} header={i18n.headerContent} />

      <main className={styles['not-found-orders']}>
        <Container>
          <button
            className={styles['not-found-orders__back-button']}
            onClick={() => history.back()}
          >
            <MdArrowBack />
          </button>
          <Row>
            <Col>
              <Typography.Subtitle
                size="l"
                className={styles['not-found-orders__title-content']}
              >
                Nenhum profissional
              </Typography.Subtitle>
              <Typography.Text
                className={styles['not-found-orders__text-content']}
              >
                Poxa! Vimos aqui que, no momento, não há profissionais
                disponíveis na sua região. Isso pode acontecer quando a demanda
                de pedidos aumenta e não conseguimos profissionais em alguns
                locais. Mas não se preocupe, estamos trabalhando muito para ter
                cada vez mais profissionais qualificados em todos os cantos do
                Brasil.
              </Typography.Text>
              <Typography.Text
                className={styles['not-found-orders__subtitle-content']}
              >
                Aí vai algumas Dicas Ninjas para te ajudar na resolução desse
                ocorrido:
              </Typography.Text>

              <Row>
                <Col className={styles['not-found-orders__list-content']}>
                  <List>
                    <ListItem>
                      Que tal refazer o seu pedido? Isso mesmo, você pode
                      esperar mais um pouquinho até aparecer um profissional ou
                      fazer outro pedido. Você que manda!
                    </ListItem>
                    <ListItem>
                      Capriche na descrição da sua necessidade. Preencha todos
                      os campos que pedimos, de forma completa, e use a parte de
                      “Descrição” para detalhar todo o serviço que será feito.
                      Quanto mais detalhes, melhor para o profissional entender
                      sua solicitação, afinal eles investem um valor para
                      liberar seu pedido.
                    </ListItem>
                  </List>
                </Col>
              </Row>
              <Typography.Text
                className={styles['not-found-orders__text-content']}
              >
                Caso precise tirar mais dúvidas ou relatar algum problema na
                solicitação do seu pedido, chame nossos Ninjas do Atendimento. É
                só ir no chat disponível no aplicativo e site, pelo e-mail:{' '}
                <span>contato@getninjas.com.br</span> ou em nossas redes
                sociais: @getninjas. Atendemos de segunda a sábado, das 8h às
                20h, exceto feriados.
              </Typography.Text>
              <Typography.Text
                className={styles['not-found-orders__text-content']}
              >
                Queremos facilitar cada vez mais a sua experiência, conte sempre
                com a gente!
              </Typography.Text>
            </Col>
            <Col
              className={`${styles['not-found-orders__buttons']} col-sm-12 col-md-6`}
            >
              <Button.Link href="/" fullWidth variation="primary">
                Tentar novamente
              </Button.Link>
              <Button.Link
                href={i18n.prosNotFoundOrder.content.helpLink}
                fullWidth
                variation="secondary"
              >
                <Button.Icon name="MdChat" />
                Conversar conosco
              </Button.Link>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
