enum EAppBannerLinks {
  CATEGORY = 'https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=floating_categorias&utm_medium=getninjas',
  CATEGORY_GEOLOC = 'https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=floating_geoloc&utm_medium=getninjas',
  BUDGET = 'https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=floating_orcamentos&utm_medium=getninjas',
  PRICE = 'https://api-mobile.getninjas.com.br/api/v1/conversions/redirect_to_download_page/cli?utm_source=floating_precos&utm_medium=getninjas',
}

type TAppBannerLinkParam = keyof typeof EAppBannerLinks;

export const getAppBannerLink = (type: TAppBannerLinkParam) => {
  return EAppBannerLinks[type];
};
