import {
  type IQuestion,
  type IQuestionSchema,
  type ISurveySchema,
} from './@interfaces';

export const createQuestionSchema = (
  acc: ISurveySchema,
  question: IQuestion
) => {
  const questionSchema: IQuestionSchema = {
    title: question.title,
    type: 'string',
  };

  if (question.description) {
    questionSchema.description = question.description;
  }

  if (question.question_type === 'rating') {
    questionSchema.type = 'number';
    questionSchema.enum = question.range;
  }

  if (question.question_type === 'single_choice') {
    questionSchema.type = 'string';
    questionSchema.enum = question.options.list.map((item) => item.id);
  }

  if (question.question_type === 'multiple_choice' && question.options.max) {
    questionSchema.anyOf = question.options.tags.map((tag) => ({
      const: tag.id,
      title: tag.value,
    }));
    questionSchema.maxItems = question.options.max;
  }

  if (question.mandatory) {
    acc.schema.required = acc.schema.required.concat(question.name);
  }

  acc.schema.properties[question.name] = questionSchema;
};
