import {
  type ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Textarea } from 'darrius';

import { useDebounce } from '../../../../../../hooks/useDebounce';
import { useForm } from '../../../../hooks/useForm';

import { type ITextareaControlProps } from './@interfaces';

export function TextareaControlComponent({
  placeholder,
  path,
  handleChange,
}: ITextareaControlProps) {
  const debounceChange = useDebounce(handleChange, 500);

  const { form } = useForm();

  const [textAreaDisplayvalue, setTextAreaDisplayValue] = useState('');

  useEffect(() => {
    if (form[path] && textAreaDisplayvalue === '') {
      setTextAreaDisplayValue(form[path]);
    }
  }, [form, path, textAreaDisplayvalue]);

  const onChange = useCallback(
    (ev: ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaDisplayValue(ev.target.value);

      debounceChange(path, ev.target.value);
    },
    [debounceChange, path]
  );

  return (
    <Textarea
      placeholder={placeholder}
      onChange={onChange}
      rows={10}
      defaultValue={textAreaDisplayvalue}
    />
  );
}

export const TextareaControl = memo(TextareaControlComponent);
