import dynamic from 'next/dynamic';
import { Button, Container } from 'darrius';

import { Status } from '../../../../../components';

import { OrderBackArrow } from '../OrderDetailsSections';
import { OrderCategoryTitle } from '../../OrderCategoryTitle/OrderCategoryTitle';

import styles from './OrderDetails.module.scss';

import { type TOrderDetailPageProps } from '../../../../../modules/orderDetails/pageProps/@interfaces';

const OrderStatusAlert = dynamic(
  () => import('../OrderDetailsSections').then((mod) => mod.OrderStatusAlert),
  {
    ssr: false,
  }
);

const OrderStatusMessage = dynamic(
  () =>
    import('../OrderDetailsSections/').then((mod) => mod.OrderStatusMessage),
  {
    ssr: false,
  }
);

const FoundProfessionalsList = dynamic(
  () =>
    import('../OrderDetailsSections/').then(
      (mod) => mod.FoundProfessionalsList
    ),
  {
    ssr: false,
  }
);

interface IOrderDetailsProps {
  onShowReviewOrder: () => void;
  order: TOrderDetailPageProps;
}

export function OrderDetails({ onShowReviewOrder, order }: IOrderDetailsProps) {
  const { status, category, categoryRoot } = order;

  function renderDetails() {
    if (status === 'blocked' || status === 'finalized_zero_lead') {
      return <OrderStatusAlert status={status} />;
    }

    if (status === 'searching' || status === 'waiting') {
      return <OrderStatusMessage status={status} />;
    }

    return (
      <FoundProfessionalsList leads={order.leads} reviewUrl={order.reviewUrl} />
    );
  }

  return (
    <main>
      <div className={styles.container}>
        <Container className={styles.container_content}>
          <OrderBackArrow to="/meus-pedidos" />

          <div className={styles.container_content__category}>
            <OrderCategoryTitle
              category={{
                text: category,
                as: 'h1',
              }}
              rootCategory={{
                text: categoryRoot,
                as: 'h2',
              }}
            />

            <Button.Root variation="clear" onClick={onShowReviewOrder}>
              Rever pedido
            </Button.Root>
          </div>

          <Status.Root>
            <Status.Title
              status={status}
              as="h3"
              remainingHours={order.remainingHours}
            />
            <Status.Description
              status={status}
              as="h4"
              totalProfessionalsFound={order.totalProfessionalsFound}
            />
          </Status.Root>
        </Container>
      </div>

      <div className={styles.content}>{renderDetails()}</div>
    </main>
  );
}
