import { Session, Chatbox } from '@talkjs/react';
import classNames from 'classnames';
import { type IChat } from './@interfaces';

export const Chat = ({
  userId,
  className = '',
  conversationId,
  isNotificationEnabled,
  onNotification,
  onGoBack = (_handler) => {},
}: IChat) => {
  return (
    <>
      {userId && (
        <Session
          appId={process.env.NEXT_PUBLIC_CHAT_APP_ID}
          userId={userId.toString()}
          desktopNotificationEnabled={isNotificationEnabled}
          onUnreadsChange={onNotification}
        >
          {conversationId.length > 0 && (
            <Chatbox
              conversationId={conversationId}
              onCustomConversationAction={onGoBack}
              theme={{
                name: 'app-rn',
                custom: { showProfileButton: false },
              }}
              className={classNames(className)}
            ></Chatbox>
          )}
        </Session>
      )}
    </>
  );
};
