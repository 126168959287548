import {
  Button,
  Col,
  Container,
  List,
  ListItem,
  Row,
  Typography,
} from 'darrius';

import { HeadContent, Header } from '../../components';

import styles from './BlockedOrderTemplate.module.scss';
import { type IOrderProps } from '../../pages/meus-pedidos';
import { MdArrowBack } from 'react-icons/md';

export function BlockedOrderTemplate({ i18n, userData }: IOrderProps) {
  return (
    <div className={styles['blocked-order']}>
      <HeadContent
        metaTitle={i18n.blockedOrder.title}
        metaDescription={i18n.blockedOrder.description}
        pageType={i18n.blockedOrder.pageType}
        keywords={i18n.blockedOrder.keywords}
        noindex
      />

      <Header.Auth userData={userData} header={i18n.headerContent} />

      <main className={styles['blocked-order__title']}>
        <Container>
          <button
            className={styles['blocked-order__back-button']}
            onClick={() => history.back()}
          >
            <MdArrowBack />
          </button>
          <Row>
            <Col>
              <Typography.Subtitle
                size="l"
                className={styles['blocked-order__title-content']}
              >
                Pedido bloqueado
              </Typography.Subtitle>
              <Typography.Text
                className={styles['blocked-order__text-content']}
              >
                Vimos aqui que seu pedido foi bloqueado. Isso pode acontecer
                quando nossas regras e termos de uso são infringidos. Todos os
                serviços e categorias dos nossos profissionais são
                regulamentados e não aceitamos práticas que violem nossa
                plataforma.
              </Typography.Text>
              <Typography.Text
                className={styles['blocked-order__text-content']}
              >
                <b>Importante:</b> caso esse comportamento seja detectado mais
                de uma vez, seu cadastro poderá ser bloqueado.
              </Typography.Text>
              <Typography.Text
                className={styles['blocked-order__subtitle-content']}
              >
                Algumas Dicas Ninjas para evitar esse bloqueio:
              </Typography.Text>

              <Row>
                <Col className={styles['blocked-order__list-content']}>
                  <List>
                    <ListItem>
                      Certifique-se que o serviço que precisa não infringe
                      nenhuma lei;
                    </ListItem>
                    <ListItem>
                      Capriche na descrição da sua necessidade. Preencha todos
                      os campos que pedimos, de forma completa, e use a parte de
                      “Descrição” para detalhar todo o serviço que será feito;
                    </ListItem>
                    <ListItem>
                      Os profissionais investem um valor para liberar o seu
                      pedido e quanto mais informações, melhor! Isso ajuda eles
                      no entendimento da solicitação e negociação com você.
                    </ListItem>
                  </List>
                </Col>
              </Row>
            </Col>
            <Col
              className={`${styles['blocked-order__buttons']} col-sm-12 col-md-6`}
            >
              <Button.Link
                href={i18n.blockedOrder.content.helpLink}
                fullWidth
                variation="primary"
              >
                <Button.Icon name="MdChat" />
                Conversar conosco
              </Button.Link>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
