import { type TStatus } from '../../../../components/atoms/Status/Status';

export function getInfoToAuxiliaryPages(status: TStatus) {
  if (status === 'finalized_zero_lead') {
    return {
      href: '/meus-pedidos/profissionais-nao-encontrados',
      text: 'Saiba mais'
    }
  } else if (status === 'blocked') {
    return {
      href: '/meus-pedidos/pedido-bloqueado',
      text: 'Nossas regras'
    }
  }
  return null;
};
