import { useState } from 'react';
import dynamic from 'next/dynamic';

import { getFlashTitle } from '../../utils';

import { HeadContent, Header } from '../../components';

import { OrderDetails } from './components/OrderDetailsContent';

import styles from './OrderDetailsTemplate.module.scss';

import { type IOrderDetailPageProps } from '../../pages/meus-pedidos/[uuid]';

const ReviewOrder = dynamic(
  () =>
    import('./components/OrderDetailsContent').then((mod) => mod.ReviewOrder),
  {
    ssr: false,
  }
);

export function OrderDetailsTemplate({
  order,
  userData,
  i18n,
}: IOrderDetailPageProps) {
  const [shouldShowReviewOrder, setShouldShowReviewOrder] = useState(false);

  const flashTitle = getFlashTitle(userData.notificationCount || 0);

  function handleShowReviewOrder() {
    setShouldShowReviewOrder(
      (oldShouldShowReviewOrderState) => !oldShouldShowReviewOrderState
    );
  }

  return (
    <div className={styles.container}>
      <HeadContent
        flashTitle={flashTitle}
        metaTitle={order.meta.title}
        metaDescription={order.meta.description}
        pageType={order.meta.pageType}
        keywords={order.meta.keywords}
        noindex
      />

      <Header.Auth userData={userData} header={i18n.headerContent} />

      {!shouldShowReviewOrder ? (
        <OrderDetails order={order} onShowReviewOrder={handleShowReviewOrder} />
      ) : (
        <ReviewOrder
          onShowReviewOrder={handleShowReviewOrder}
          reviewData={order.reviewData}
        />
      )}
    </div>
  );
}
