import { last } from './last';
import { splitPath } from './splitPath';

interface IRuleParams {
  condition?: {
    scope?: string;
    schema?: {
      enum: unknown[];
    };
  };
}

type IDataParams = Record<string, string>;

export const checkComponentIsVisible = (
  rule: IRuleParams,
  data: IDataParams
) => {
  if (rule) {
    const currentCondition = rule.condition;
    const pathSlice = splitPath(currentCondition.scope);
    const currentScope = last(pathSlice);

    return currentCondition.schema.enum.includes(
      parseInt(data[currentScope], 10)
    );
  }

  return true;
};
