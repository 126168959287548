import { useEffect } from 'react';

import Link from 'next/link';

import classnames from 'classnames';

import {
  Breadcrumb,
  Banner,
  AppBanner,
  Container,
  Col,
  Button,
  Paragraph,
  Row,
  TextLink,
  Footer,
  List,
  ListItem,
} from 'darrius';

import { getAppBannerLink } from '../../utils/getAppBannerLink';
import { getFlashTitle } from '../../utils/getFlashTitle';
import { getPrepositionOf } from '../../utils/getPrepositionOf';
import { triggerEvent } from '../../utils';

import {
  Review,
  HeadContent,
  Badges,
  PriceBanner,
  PriceRange,
  Header,
} from '../../components';

import styles from './PriceTemplate.module.scss';

import { type TParagraphSize } from 'darrius/build/types/components/molecules/Paragraph/Paragraph';

import { type IPricePageProps } from '../../modules/price/pageProps/@interfaces';

export const PriceTemplate = ({
  dynamicDataCategory,
  internalLinks,
  footerContent,
  headerContent,
  dynamicDataCms,
  userData,
}: IPricePageProps) => {
  const { children_flat: childrenFlat } = internalLinks;

  const { pricePage, pricesByCategory } = dynamicDataCms;

  const {
    categorySlug,
    slug,
    content,
    metaTitle,
    metaDescription,
    minimumPrice,
    maximumPrice,
    mediumPrice,
    title,
  } = pricePage;

  const {
    breadcrumbs,
    bestReviews,
    root,
    semOccupationSingular,
    semOccupationPlural,
  } = dynamicDataCategory;

  const categoryName = root.name;

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'brl',
  });

  const bannerLink = getAppBannerLink('PRICE');

  const flashTitle = getFlashTitle(userData.notificationCount || 0);

  useEffect(() => {
    const trackingTimeout = setTimeout(() => {
      triggerEvent('chat__nav-message', {
        userId: userData?.id,
      });
    }, 5000);
    return () => clearTimeout(trackingTimeout);
  }, [userData]);

  return (
    <>
      <AppBanner link={bannerLink} />

      <HeadContent
        flashTitle={flashTitle}
        metaTitle={metaTitle}
        canonicalLink={`https://www.getninjas.com.br/${categorySlug}/preco/${slug}`}
        metaDescription={metaDescription}
        pageType="pages:price"
      />

      <Container>
        <Header.Auth userData={userData} header={headerContent} />
      </Container>

      <Banner className={`${styles.price__hero} no-space-top`}>
        <Row>
          <Col className="col-sm-12 col-md-5">
            <Breadcrumb className="hide-sm" breadcrumbs={breadcrumbs} />

            <Paragraph
              size="1"
              title={`Quanto custa ${title}?`}
              text="Fale o que precisa, receba até 4 orçamentos, escolha o melhor."
            />

            <Badges />
          </Col>

          <Col className="col-sm-12 col-md-6 col-md-offset-1">
            <PriceBanner
              name={title}
              categorySlug={categorySlug}
              slug={slug}
              minimum={formatter.format(minimumPrice)}
              maximum={formatter.format(maximumPrice)}
              medium={formatter.format(mediumPrice)}
            />
          </Col>
        </Row>
      </Banner>

      {content.length > 0 && (
        <Banner title="Análise de Preço" size="2">
          <Row>
            <Col
              className={`${styles['price-analysis']} price-analysis col-sm-12 col-md-8 col-md-offset-2`}
            >
              <Paragraph
                className={styles['price-analysis__title-medium']}
                text="Índice"
                size="2"
              />

              <Container as="ul">
                {content.map((item) => (
                  <li
                    key={item.id}
                    className={classnames('link-list-item', {
                      'link-list-item--child': item.tagsize === 3,
                    })}
                  >
                    <TextLink text={item.title} url={`#${item.id}`} />
                  </li>
                ))}
              </Container>

              {content.map((item) => (
                <div key={item.id} id={String(item.id)} className="space-top">
                  <Paragraph
                    title={item.title}
                    size={String(item.tagsize) as TParagraphSize}
                  />

                  <Paragraph text={item.description} size="3" />

                  <Row>
                    <Col className="col-md-12">
                      {item.list.map((value) => (
                        <ul key={value.item}>
                          <li>{value.item}</li>
                        </ul>
                      ))}
                    </Col>
                  </Row>

                  {item.links.map((related) => (
                    <ul
                      key={related.url}
                      className={`${styles['link-list-item__no-list-style']} link-list-item__no-list-style`}
                    >
                      <li>
                        <a href={related.url}>{related.title}</a>
                      </li>
                    </ul>
                  ))}
                </div>
              ))}
            </Col>
          </Row>
        </Banner>
      )}
      <Banner title="Avaliações de quem contratou" size="2" text={title}>
        <Row className="space-between">
          {bestReviews.length > 0 &&
            bestReviews.map((review) => {
              const reviewContent = {
                content: review.content,
                pro: review.profile.user.name,
                client: review.name,
                category: categoryName,
                rating: review.value,
              };

              return (
                <Col className="col-sm-12 col-md-4" key={review.name}>
                  <Review {...reviewContent} />
                </Col>
              );
            })}
        </Row>
      </Banner>

      {pricesByCategory.length > 0 && (
        <Banner title="Custo de Serviços" size="2">
          <Row className="center-md">
            {pricesByCategory.map((item) => (
              <Col key={item.id} className="col-sm-12 col-md-4 space-top">
                <PriceRange
                  categorySlug={item.categorySlug}
                  slug={item.slug}
                  name={item.title}
                  minimum={formatter.format(item.minimumPrice)}
                  maximum={formatter.format(item.maximumPrice)}
                  medium={formatter.format(item.mediumPrice)}
                />
              </Col>
            ))}
          </Row>
        </Banner>
      )}

      <Banner
        className="full"
        size="2"
        title="Contrate profissionais para reformas e reparos"
        text="Até 4 profissionais entrarão em contato, negocie e contrate o melhor"
      >
        <Row className="space-top center-sm">
          <Col className="col-sm-12 col-md-5">
            <Link href={`/${categorySlug}`} passHref legacyBehavior>
              <Button.Link className="primary" fullWidth>
                Solicitar Orçamento
              </Button.Link>
            </Link>
          </Col>
        </Row>
      </Banner>

      {!!childrenFlat && childrenFlat.length > 0 && (
        <Banner>
          <Paragraph
            size="3"
            title={`Veja Serviços Similares a ${semOccupationSingular}`}
          />

          <List
            className={`${styles['list-component__price']} list-component__price`}
          >
            {childrenFlat.map((related) => (
              <ListItem key={related.name}>
                <TextLink
                  key={related.name}
                  url={`/${related.slug}`}
                  text={related.name}
                />
              </ListItem>
            ))}
          </List>
        </Banner>
      )}

      {internalLinks && internalLinks.states.length > 0 && (
        <Banner>
          <Paragraph
            size="3"
            title={`Encontre ${semOccupationPlural} em Todos os Estados`}
          />

          <List
            className={`${styles['list-component__price']} list-component__price`}
          >
            {internalLinks.states.map((state) => (
              <ListItem key={state.slug}>
                <TextLink
                  key={state.uf}
                  url={`/${categorySlug}/${state.slug}`}
                  text={`${semOccupationPlural} ${getPrepositionOf('state', {
                    uf: state.uf,
                    name: state.name,
                  })} ${state.name}`}
                />
              </ListItem>
            ))}
          </List>
        </Banner>
      )}

      {internalLinks && internalLinks.cities.length > 0 && (
        <Banner>
          <Paragraph
            size="3"
            title={`Encontre ${semOccupationPlural} nas Principais Cidades`}
          />

          <List
            className={`${styles['list-component__price']} list-component__price`}
          >
            {internalLinks.cities.map((city) => (
              <ListItem key={city.name}>
                <TextLink
                  key={city.name}
                  url={`/${categorySlug}/${city.slug}`}
                  text={`${semOccupationPlural} ${getPrepositionOf('city', {
                    uf: city.uf,
                    name: city.name,
                  })} ${city.name}`}
                />
              </ListItem>
            ))}
          </List>
        </Banner>
      )}

      <Banner className="hide-md">
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </Banner>

      <Footer data={footerContent} />
    </>
  );
};
