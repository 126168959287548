import Image from 'next/image';

import { Button, Col, Row, TextField, Container } from 'darrius';

import { useSignIn } from '../../../context/SignInContext/SignInContext';

import { Dialog } from '../../../components';

import { SignInUnregisteredDialog } from './SignInUnregisteredDialog/SignInUnregisteredDialog';

import styles from './SignInPhoneNumber.module.scss';

import { type TPhoneScreen } from '../../../pages/entrar';
import { imgPrefix } from '../../../utils';

type ISignInPhoneNumberProps = TPhoneScreen;

export const SignInPhoneNumber = ({
  title,
  subtitle,
  image,
  input,
  sendSms,
  unregisteredDialog,
}: ISignInPhoneNumberProps) => {
  const { phone, handleUserPhoneNumber, handleSendPinToNumber, isPinSending } =
    useSignIn();

  function handleInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleSendPinToNumber();
    }
  }

  return (
    <Dialog.Root>
      <Container>
        <Row className="center">
          <Col className={`${styles.signin__details} col-sm-10`}>
            <Image
              src={imgPrefix(image.src)}
              alt=""
              height={image.size.height}
              width={image.size.width}
            />

            <h1 className={styles.signin__title}>{title}</h1>
            <p className={styles.signin__subtitle}>{subtitle}</p>
          </Col>
        </Row>

        <Row className="center">
          <Col className={`${styles.signin__content_input} col-sm-10`}>
            <TextField
              id="phone"
              label={input.label}
              placeholder={input.placeholder}
              value={phone.formatted}
              onChange={handleUserPhoneNumber}
              onKeyDown={handleInputKeyDown}
              maxLength={15}
              type="tel"
            />

            <Button.Root
              onClick={handleSendPinToNumber}
              disabled={isPinSending}
              isLoading={isPinSending}
            >
              {sendSms.buttons.sendPin.text}
            </Button.Root>
            <Dialog.Trigger asChild>
              <Button.Root variation="secondary" disabled={isPinSending}>
                {sendSms.buttons.unregistered.text}
              </Button.Root>
            </Dialog.Trigger>

            <p className={styles.signin__pro}>
              {sendSms.buttons.professional.preText}{' '}
              <a
                href={sendSms.buttons.professional.link.href}
                className={styles.signin__pro_link}
              >
                {sendSms.buttons.professional.link.text}
              </a>{' '}
              {sendSms.buttons.professional.postText}
            </p>
          </Col>
        </Row>
      </Container>

      <SignInUnregisteredDialog {...unregisteredDialog} />
    </Dialog.Root>
  );
};
