import { useEffect } from 'react';

import { type ISurveyPageProps } from '../../modules/survey/pageProps/@interfaces';

import { useTemplate } from '../../utils/form/hooks/useTemplate';

import { SurveyDecision } from './SurveyDecision';
import { SurveyFinished } from './SurveyFinished';
import { SurveyRequestExperience } from './SurveyRequestExperience';
import { SurveyProfileExperience } from './SurveyProfileExperience';
import { SurveyLoading } from './SurveyLoading';

export function SurveyTemplate({
  surveyProps,
  footerContent,
  mainServices,
}: ISurveyPageProps) {
  const { handleTemplateChange, template } = useTemplate();

  const { templateToShow } = surveyProps;

  useEffect(() => {
    if (templateToShow) {
      handleTemplateChange(templateToShow);
    }
  }, [handleTemplateChange, templateToShow]);

  function RenderSurveyTemplate() {
    if (template === 'finished' || template === 'answered') {
      return (
        <SurveyFinished
          status={template}
          footerContent={footerContent}
          mainServices={mainServices}
        />
      );
    }

    if (template === 'accepted') {
      return <SurveyProfileExperience survey={surveyProps} />;
    }

    if (template === 'refused') {
      return <SurveyRequestExperience survey={surveyProps} />;
    }

    if (template === 'not-answered') {
      return <SurveyDecision />;
    }

    return <SurveyLoading />;
  }

  return <RenderSurveyTemplate />;
}
