import { useEffect, useState } from 'react';

import { HeadContent } from '../../components';

import { serviceRatingService } from '../../services/serviceRatingService';

import { axiosInstance } from '../../utils/axiosInstance';
import { useSurveySubmit } from '../../hooks/useSurveySubmit';

import { SurveyLoading } from './SurveyLoading';

import { Form } from '../../utils/form/components/Form';
import { Steps, StepsTester } from '../../utils/form/components/Steps';
import { Group, GroupTester } from '../../utils/form/components/Group';
import { Tags, TagsTester } from '../../utils/form/components/Tags';
import { Textarea, TextareaTester } from '../../utils/form/components/TextArea';
import { List, ListTester } from '../../utils/form/components/List';
import { Input, InputTester } from '../../utils/form/components/Input';
import { Rating, RatingTester } from '../../utils/form/components/Rating';

type TSurvey = {
  templateToShow: string;
  userId: number;
  requestId: number;
};

interface ISurveyProfileExperienceProps {
  survey: TSurvey;
}

export const SurveyProfileExperience = ({
  survey,
}: ISurveyProfileExperienceProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [serviceRating, setServiceRating] = useState(null);

  const { handleSubmit } = useSurveySubmit({
    survey,
    ratingSchema: serviceRating,
    schemaType: 'service',
  });

  useEffect(() => {
    async function fetchDataServiceRating() {
      const { requestId } = survey;

      const getRatingService = serviceRatingService(axiosInstance);

      const response = await getRatingService(requestId);

      setServiceRating(response);

      setIsLoading(false);
    }

    fetchDataServiceRating();
  }, [survey]);

  return (
    <>
      {isLoading ? (
        <SurveyLoading />
      ) : (
        <>
          <HeadContent
            metaTitle="GetNinjas | Orçamento de Profissionais Confiáveis no GetNinjas.com.br"
            metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
            pageType="pages:survey/profile"
          />

          <Form
            schema={serviceRating.schema}
            uiSchema={serviceRating.uiSchema}
            dataSchema={serviceRating.uiData || {}}
            onSubmit={handleSubmit}
            renderers={[
              { tester: StepsTester, componentToRender: Steps },
              { tester: GroupTester, componentToRender: Group },
              { tester: TagsTester, componentToRender: Tags },
              { tester: TextareaTester, componentToRender: Textarea },
              { tester: ListTester, componentToRender: List },
              { tester: InputTester, componentToRender: Input },
              { tester: RatingTester, componentToRender: Rating },
            ]}
          />
        </>
      )}
    </>
  );
};
