import Image from 'next/image';

import { Container, Row, Col, Paragraph, Footer, SearchBar } from 'darrius';

import { HeadContent, Header, MainServices } from '../../components';

import { type ISurveyFinishedPageProps } from '../../modules/survey/pageProps/@interfaces';

import { imgPrefix, getBlurDataURL } from '../../utils';

export const SurveyFinished = ({
  footerContent,
  mainServices,
  status,
}: ISurveyFinishedPageProps) => {
  return (
    <>
      <HeadContent
        metaTitle="GetNinjas | Orçamento de Profissionais Confiáveis no GetNinjas.com.br"
        metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
        pageType={`pages:survey/${status}`}
      />

      <Container>
        <Header.Default onlyLogo />

        <Row className="start-md top-sm space-between space-top space-bottom">
          <Col className="col-sm-3 col-md-4">
            <Image
              src={imgPrefix('/images/survey/survey-finished.svg')}
              alt="Avaliação finalizada"
              width={254}
              height={234}
              placeholder="blur"
              blurDataURL={getBlurDataURL()}
            />
          </Col>

          <Col className="col-sm-12 col-md-6">
            <Paragraph
              size="1"
              title="Obrigado, já recebemos sua avaliação"
              text="Caso esteja precisando de outro serviço, aproveite para buscar novos profissionais"
            />

            <SearchBar action="/requests/new" />
          </Col>
        </Row>
      </Container>

      <MainServices data={mainServices} />

      <Footer data={footerContent} />
    </>
  );
};
