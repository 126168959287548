import { Icon as DarriusIcon } from 'darrius';

import {
  type AlertRootProps,
  type AlertProps,
  type AlertIconProps,
} from './@interfaces';

import styles from './Alert.module.scss';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
const Root = ({ children, onClick }: AlertRootProps) => (
  <div className={styles.root} onClick={onClick}>
    {children}
  </div>
);
/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const Icon = ({ image, description }: AlertIconProps) => (
  <DarriusIcon src={image} alt={description} size={36} />
);

const Link = ({ image, description }: AlertIconProps) => (
  <DarriusIcon
    className={styles.link}
    src={image}
    alt={description}
    size={24}
  />
);

const Body = ({ children }: AlertProps) => <div>{children}</div>;

const Title = ({ children }: AlertProps) => (
  <p className={styles.title}>{children}</p>
);

const Text = ({ children }: AlertProps) => (
  <p className={styles.text}>{children}</p>
);

export const Alert = { Root, Icon, Link, Body, Title, Text };
