import { useEffect, useState } from 'react';

import { experienceRatingService } from '../../services/experienceRatingService';

import { HeadContent } from '../../components';

import { SurveyLoading } from './SurveyLoading';

import { useSurveySubmit } from '../../hooks/useSurveySubmit';

import { axiosInstance } from '../../utils/axiosInstance';

import { Form } from '../../utils/form/components/Form';
import { Steps, StepsTester } from '../../utils/form/components/Steps';
import { Group, GroupTester } from '../../utils/form/components/Group';
import { Tags, TagsTester } from '../../utils/form/components/Tags';
import { Textarea, TextareaTester } from '../../utils/form/components/TextArea';
import { List, ListTester } from '../../utils/form/components/List';

type TSurvey = {
  templateToShow: string;
  userId: number;
  requestId: number;
};

interface ISurveyRequestExperienceProps {
  survey: TSurvey;
}

export function SurveyRequestExperience({
  survey,
}: ISurveyRequestExperienceProps) {
  const [loading, setLoading] = useState(true);
  const [experienceRating, setExperienceRating] = useState(null);

  const { handleSubmit } = useSurveySubmit({
    survey,
    ratingSchema: experienceRating,
    schemaType: 'experience',
  });

  useEffect(() => {
    async function fetchDataServiceRating() {
      const getExperienceRating = experienceRatingService(axiosInstance);

      const response = await getExperienceRating();

      setExperienceRating(response);

      setLoading(false);
    }

    fetchDataServiceRating();
  }, []);

  return (
    <>
      {loading ? (
        <SurveyLoading />
      ) : (
        <>
          <HeadContent
            metaTitle="GetNinjas | Orçamento de Profissionais Confiáveis no GetNinjas.com.br"
            metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
            pageType="pages:survey/request"
          />

          <Form
            schema={experienceRating.schema}
            uiSchema={experienceRating.uiSchema}
            dataSchema={experienceRating.uiData || {}}
            onSubmit={handleSubmit}
            renderers={[
              { tester: StepsTester, componentToRender: Steps },
              { tester: GroupTester, componentToRender: Group },
              { tester: TagsTester, componentToRender: Tags },
              { tester: TextareaTester, componentToRender: Textarea },
              { tester: ListTester, componentToRender: List },
            ]}
          />
        </>
      )}
    </>
  );
}
