import Image from 'next/image';

import { Container, Typography } from 'darrius';

import { imgPrefix } from '../../../../../../utils';

import styles from './OrderStatusMessage.module.scss';

import { type TStatus } from '../../../../../../components/atoms/Status/Status';

interface IOrderStatusMessageProps {
  status: Extract<TStatus, 'waiting' | 'searching'>;
}

export function OrderStatusMessage({ status }: IOrderStatusMessageProps) {
  const title = {
    waiting: 'Agora é só aguardar',
    searching: 'Falta pouco',
  };

  const description = {
    waiting:
      'Seu pedido está em análise para encontrarmos os melhores profissionais.',
    searching:
      'Você receberá um e-mail assim que encontrarmos profissionais. Os resultados aparecerão aqui.',
  };

  const image = {
    waiting: '/images/orders/clock.svg',
    searching: '/images/orders/magnifying-glass.svg',
  };

  return (
    <Container className={styles.container}>
      <Image
        src={imgPrefix(image[status])}
        alt=""
        width={72}
        height={72}
        quality={100}
      />

      <Typography.Subtitle as="h5" size="l">
        {title[status]}
      </Typography.Subtitle>

      <Typography.Text as="p" className={styles.description}>
        {description[status]}
      </Typography.Text>
    </Container>
  );
}
