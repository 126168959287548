import { useEffect, useState } from 'react';

export function useRectForm() {
  const [isMiddleForm, setIsMiddleForm] = useState(false);
  const [isFinalForm, setIsFinalForm] = useState(false);

  useEffect(() => {
    const getForm = document.querySelector('#form');
    const screenHeight = window.innerHeight;

    document.addEventListener('scroll', function () {
      if (getForm.getBoundingClientRect().bottom / 2 <= screenHeight) {
        return setIsMiddleForm(true);
      }
    });

    document.addEventListener('scroll', function () {
      if (
        getForm.getBoundingClientRect().bottom <= screenHeight &&
        getForm.getBoundingClientRect().bottom >= 0
      ) {
        return setIsFinalForm(true);
      }
    });
  }, []);

  return {
    isFinalForm,
    isMiddleForm,
  };
}
