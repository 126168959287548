import { TestAndRender } from '../TestAndRender';

import { type TSurveyDefaultProps } from '../@interfaces';

export function Form({
  schema,
  uiSchema,
  dataSchema,
  renderers,
  onSubmit,
}: TSurveyDefaultProps) {
  return (
    <TestAndRender
      schema={schema}
      uiSchema={uiSchema}
      dataSchema={dataSchema}
      renderers={renderers}
      onSubmit={onSubmit}
    />
  );
}
