import { Container, Paragraph } from 'darrius';

import { getFlashTitle } from '../../utils';

import { HeadContent, Header } from '../../components';

import { EmptyOrderList } from './components/EmptyOrderList/EmptyOrderList';
import { OrderCard } from './components/OrderCard/OrderCard';

import styles from './OrdersTemplate.module.scss';

import { type IOrderProps } from '../../pages/meus-pedidos';

export function OrdersTemplate({ i18n, userData, orders }: IOrderProps) {
  const flashTitle = getFlashTitle(userData.notificationCount || 0);

  return (
    <div className={styles.orders__container}>
      <HeadContent
        flashTitle={flashTitle}
        metaTitle={i18n.meta.title}
        metaDescription={i18n.meta.description}
        pageType={i18n.meta.pageType}
        keywords={i18n.meta.keywords}
        noindex
      />

      <Header.Auth userData={userData} header={i18n.headerContent} />

      <div className={styles.orders__title}>
        <Container>
          <Paragraph title="Meus Pedidos" size="2" />
        </Container>
      </div>

      <main className={`${styles.orders__content} center`}>
        {orders.length === 0 ? (
          <EmptyOrderList i18n={i18n} />
        ) : (
          <ul>
            {orders.map((order) => (
              <OrderCard key={order.id} order={order} />
            ))}
          </ul>
        )}
      </main>
    </div>
  );
}
