import Image from 'next/image';

import { Button, Paragraph } from 'darrius';

import { navigate } from '../../../../utils';

import { Dialog } from '../../../../components';

import { type TUnregisteredDialog } from '../../../../pages/entrar';

export function SignInUnregisteredDialog({
  title,
  subtitle,
  image,
  button,
}: TUnregisteredDialog) {
  function handleDownloadApp() {
    navigate(button.link, false);
  }

  return (
    <Dialog.Content>
      <Image
        src={image.src}
        alt=""
        height={image.size.height}
        width={image.size.width}
      />

      <Paragraph title={title} text={subtitle} size="2" headline />

      <Button.Root onClick={handleDownloadApp} fullWidth>
        {button.text}
      </Button.Root>
    </Dialog.Content>
  );
}
