import { z } from 'zod';

const schemaPhone = z.object({
  phone: z
    .string()
    .max(15)
    .regex(/^\(?[1-9]{2}\)?\s?9[1-9][0-9]{3}\-?[0-9]{4}$/),
  formattedPhone: z.string().optional(),
});

export { schemaPhone };
