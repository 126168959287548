import { useEffect } from 'react';

import Image from 'next/image';

import {
  Container,
  Row,
  Col,
  Paragraph,
  Banner,
  Footer,
  Breadcrumb,
} from 'darrius';

import dataHeroMock from '../../modules/instantMatch/mocks/dataHeroMock.json';

import { getBlurDataURL, imgPrefix, triggerEvent } from '../../utils';
import { getFlashTitle } from '../../utils/getFlashTitle';
import { INSTANT_MATCH_V1_IS_ENABLED } from '../../utils/featureFlags/instantMatchV1';

import { HeadContent, Header, ProCardDetail } from '../../components';

import { ProsNotFound } from './ProsNotFound/ProsNotFound';
import { SkipIndicationsDialog } from './SkipIndicationsDialog/SkipIndicationsDialog';

import styles from './InstantMatchTemplate.module.scss';

import { type TPro } from '../../modules/instantMatch/transformations';
import { type IInstantMatch } from '../../pages/requests/[uuid]/contato-instantaneo';

export const InstantMatchTemplate = (props: IInstantMatch) => {
  const {
    meta,
    headerContent,
    categorySlug,
    pros,
    breadcrumbs,
    footerContent,
    heroSectionTitle,
    areProsNotFound = false,
    requestId,
    userData,
  } = props;

  const flashTitle = getFlashTitle(userData.notificationCount || 0);

  useEffect(() => {
    const trackingTimeout = setTimeout(() => {
      triggerEvent('chat__nav-message', {
        userId: userData?.id,
      });
    }, 5000);
    return () => clearTimeout(trackingTimeout);
  }, [userData]);

  useEffect(() => {
    const trackingTimeout = setTimeout(() => {
      triggerEvent('instant-match-profile-list__inicialization', {
        requestId,
      });

      if (areProsNotFound) {
        triggerEvent('instant-match-profile-list__not-found', {
          requestId,
        });
      } else {
        pros.map((profile, index) => {
          const proEvent = {
            profile: profile.id,
            index: index + 1,
            requestId,
          };

          if (INSTANT_MATCH_V1_IS_ENABLED) {
            Object.assign(proEvent, {
              price: profile.servicePrice,
            });
          }

          triggerEvent('instant-match-profile-list__all-profiles', proEvent);
        });
      }
    }, 1000);

    return () => clearTimeout(trackingTimeout);
  }, [areProsNotFound, pros, requestId]);

  function renderPro(pro: TPro, index: number) {
    const {
      id,
      link,
      name,
      photo,
      rating,
      isVerifiedPro,
      requestId: proRequestId,
      isInstitutionalPro,
      servicePrice,
    } = pro;

    if (INSTANT_MATCH_V1_IS_ENABLED && isInstitutionalPro) {
      return (
        <ProCardDetail.Institutional
          id={id}
          link={link}
          name={name}
          photo={photo}
          rating={rating}
          isVerifiedPro={isVerifiedPro}
          servicePrice={servicePrice}
          requestId={proRequestId}
        />
      );
    }

    return (
      <ProCardDetail.Default
        id={id}
        index={index}
        link={link}
        name={name}
        photo={photo}
        rating={rating}
        isVerifiedPro={isVerifiedPro}
        requestId={proRequestId}
      />
    );
  }

  function renderInstantMatchMainContent() {
    if (areProsNotFound) {
      return (
        <Banner>
          <ProsNotFound />
        </Banner>
      );
    }

    return (
      <Banner className="pros full">
        <Banner className={styles.banner}>
          <Row className={`${styles['row-pro-card']} center-sm`}>
            <Col>
              {pros.map((pro, index) => (
                <Row
                  className={`${styles['row-pro-card']} center-sm`}
                  key={pro.id}
                >
                  {renderPro(pro, index)}
                </Row>
              ))}
            </Col>
            <SkipIndicationsDialog requestId={requestId} />
          </Row>
        </Banner>
      </Banner>
    );
  }

  return (
    <>
      <HeadContent
        flashTitle={flashTitle}
        metaTitle={meta.title}
        metaDescription={meta.description}
        pageType="instant-match:show"
        slug={categorySlug}
        noindex
      />

      <Header.Auth userData={userData} header={headerContent} />

      <main
        className={`${styles['category-page']} instant-match category-page`}
        data-category-slug={categorySlug}
      >
        <Container className={`${styles['main-section']} main-section`}>
          <Row>
            <Col
              className={`${styles['main-section__title-area']} col-sm-12 col-md-6 main-section__title-area left`}
            >
              <Breadcrumb breadcrumbs={breadcrumbs.items} className="hide-sm" />

              <script
                id="instant-match-configs"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: breadcrumbs.structuredData,
                }}
              />

              <p
                className={`${styles['main-section__text']} main-section__text`}
              >
                Resultados em
              </p>

              <Paragraph
                title={heroSectionTitle}
                className={`${styles['main-section__title']} main-section__title`}
              />
            </Col>

            <Col
              className={`${styles['main-section__image']} col-sm-12 col-md-6 main-section__image hide-sm center-sm`}
            >
              <Image
                src={imgPrefix(dataHeroMock.image.src)}
                alt={dataHeroMock.image.alt}
                width={300}
                height={219.29}
                className={`${styles['max-width-full']} ${styles['main-section__hero-image']}`}
                placeholder="blur"
                blurDataURL={getBlurDataURL()}
                priority
              />
            </Col>
          </Row>
        </Container>

        {renderInstantMatchMainContent()}
      </main>

      <Footer data={footerContent} />
    </>
  );
};
