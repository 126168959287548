import { useCallback } from 'react';

import {
  type ISurvey,
  type ISurveySchema,
} from '../modules/survey/@interfaces';

import { notifyErrorClient } from '../services/notifyError/client';

import { axiosInstance } from '../utils/axiosInstance';
import { errorPrefix } from '../utils/errorPrefix';
import { triggerEvent } from '../utils';

interface IUseSurveySubmitParams {
  survey: ISurvey;
  ratingSchema: ISurveySchema;
  schemaType: string;
}

export function useSurveySubmit({
  survey,
  ratingSchema,
  schemaType,
}: IUseSurveySubmitParams) {
  const getSchemaTypeData = useCallback(
    (data) => {
      if (schemaType === 'experience') {
        return {
          rating: null,
          comment: data.como_podemos_te_ajudar_no_seu_proximo_pedido || null,
        };
      }

      if (schemaType === 'service') {
        const { id, leadId } = ratingSchema.uiData.professionals.find(
          (item) => item.id === data.professional
        );

        return {
          rating: data.escolha_de_1_a_5_estrelas_para_avaliar,
          comment:
            data.gostaria_de_fazer_algum_comentario_sobre_o_servico_prestado ||
            null,
          profile_id: id,
          lead_id: leadId,
        };
      }

      throw new Error('SchemaType not allowed.');
    },
    [ratingSchema, schemaType]
  );

  const handleSubmit = useCallback(
    async (data) => {
      let wasSuccess = false;
      const { requestId, userId } = survey;

      try {
        const formSnapshot = Object.entries(ratingSchema.schema.properties)
          .filter(([key]) => data[key] !== undefined)
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [value.title]: data[key],
            }),
            {}
          );

        const dataToSend = {
          user_id: userId,
          request_id: requestId,
          survey_form_id: ratingSchema.id,
          form_snapshot: formSnapshot,
          ...getSchemaTypeData(data),
        };

        await axiosInstance({
          url: '/api/survey/form-submit',
          method: 'POST',
          data: dataToSend,
        });

        wasSuccess = true;
      } catch (error) {
        notifyErrorClient(errorPrefix('[Survey-Submit]', error));
      }

      triggerEvent('review-survey', {
        wasSuccess,
        request: requestId,
      });
    },
    [getSchemaTypeData, ratingSchema, survey]
  );

  return {
    handleSubmit,
  };
}
