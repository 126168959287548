import { type ChangeEvent, useState } from 'react';
import { Button, TextField } from 'darrius';

import { addPhoneMask } from '../../../utils/addPhoneMask';
import { triggerEvent } from '../../../utils';

import { usePhoneVerification } from '../../../context/PhoneVerificationContext/PhoneVerificationContext';

import { Dialog } from '../../../components/atoms';
import { showToast } from '../../../components/atoms/Toast/Toast';

import styles from './ChangeNumberModal.module.scss';

const ChangeNumberModal = () => {
  const { values: phoneVerificationValues, methods: phoneVerificationMethods } =
    usePhoneVerification();

  const { phoneVerificationData } = phoneVerificationValues;
  const { onSubmitNewPhoneNumber } = phoneVerificationMethods;

  const { resend, validation } = phoneVerificationData;

  const { buttonText, messages, placeholder, title } = resend.modal;

  const buttonTextInUpperCase = buttonText.toUpperCase();

  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [isPhoneNumberChanging, setIsPhoneNumberChanging] = useState(false);
  const [numberIsInvalidMessage, setNumberIsInvalidMessage] = useState('');
  const [hasErrorOnSendingNewNumber, setHasErrorOnSendingNewNumber] =
    useState(false);

  const newNumberHasError = hasErrorOnSendingNewNumber && messages.error;

  const numberIsInvalid = !!newNumberHasError || !!numberIsInvalidMessage;

  function numberValidation(phoneNumber: string) {
    setNumberIsInvalidMessage('');

    if (
      validation &&
      validation.phone &&
      !new RegExp(validation.phone.regexp).test(phoneNumber)
    ) {
      setNumberIsInvalidMessage(validation.phone.error);

      triggerEvent('phone_verification-change_number_modal_invalid', {
        phoneNumber,
      });
    }
  }

  function handleNewPhoneNumberChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    numberValidation(value);

    const phoneNumber = addPhoneMask(value);

    setNewPhoneNumber(phoneNumber);
  }

  async function handleSubmitNewPhoneNumber(
    event: ChangeEvent<HTMLFormElement>
  ) {
    event.preventDefault();

    setHasErrorOnSendingNewNumber(false);
    setIsPhoneNumberChanging(true);

    const response = await onSubmitNewPhoneNumber(newPhoneNumber);

    const isSuccessInSubmitNewPhoneNumber = response.status === 'ok';

    if (isSuccessInSubmitNewPhoneNumber) {
      setNewPhoneNumber('');
      setIsPhoneNumberChanging(false);

      showToast({
        message: 'Número alterado com sucesso!',
        type: 'success',
        id: 'change-number-success',
      });

      return;
    }

    setHasErrorOnSendingNewNumber(true);
    setIsPhoneNumberChanging(false);
  }

  return (
    <Dialog.Portal>
      <Dialog.Content
        title={{
          text: title,
          hidden: true,
        }}
        loading={{
          enabled: isPhoneNumberChanging,
          title: messages.loading,
        }}
      >
        <form
          onSubmit={handleSubmitNewPhoneNumber}
          className={styles.changeNumber__form}
        >
          <TextField
            id="new-number"
            label={title}
            onChange={handleNewPhoneNumberChange}
            value={newPhoneNumber}
            placeholder={placeholder}
            errorMessage={newNumberHasError || numberIsInvalidMessage}
          />

          <Button.Root
            type="submit"
            variation="secondary"
            fullWidth
            size="small"
            disabled={
              numberIsInvalid || isPhoneNumberChanging || !newPhoneNumber
            }
          >
            {buttonTextInUpperCase}
          </Button.Root>
        </form>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

export default ChangeNumberModal;
