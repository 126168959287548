import { isWebview } from '../../utils/isWebview';
import { Chat } from './Chat/Chat';

import styles from './ChatWebviewTemplate.module.scss';

const CLOSE_EVENT = 'closeChatWebview';

export const ChatWebviewTemplate = ({ userId, conversationId }) => {
  const handleChatGoBack = () => {
    if (isWebview(window.navigator)) {
      try {
        // @ts-expect-error
        if (window?.Android) {
          // @ts-expect-error
          window.Android.postMessage(CLOSE_EVENT);
        }
        if (
          // @ts-expect-error
          window?.webkit?.messageHandlers &&
          // @ts-expect-error
          CLOSE_EVENT in window.webkit.messageHandlers
        ) {
          // @ts-expect-error
          window.webkit.messageHandlers[CLOSE_EVENT].postMessage(CLOSE_EVENT);
        }
      } catch (err) {
        console.error('Tivemos um erro ao clicar no botão', err);
      }
    }
  };

  return (
    <Chat
      userId={userId}
      conversationId={conversationId}
      onNotification={() => {}}
      isNotificationEnabled={false}
      onGoBack={handleChatGoBack}
      className={styles['chat-content']}
    />
  );
};
