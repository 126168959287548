import Image from 'next/image';

import { Banner, Paragraph, Row, Col, Tag } from 'darrius';

import { FlatCard, FlatCardBody } from '../../../components';

import { imgPrefix } from '../../../utils';

import { type IMostSearchedServicesRequest } from '../../../locales/@interfaces/shared/request/mostSearchedServices';

import styles from './RequestMostSearchedServices.module.scss';

export const RequestMostSearchedServices = ({
  title,
  items,
  onlineService,
  button,
}: IMostSearchedServicesRequest) => (
  <Banner as="section" className="most-searched-services full">
    <Paragraph size="2" title={title} headline />

    <Row className="center-sm">
      {items.map((service) => (
        <Col key={service.slug} className="col-sm-12 col-md-4 col-lg-3">
          <a href={`/${service.slug}`}>
            <FlatCard className={styles.service} selectable>
              <FlatCardBody>
                <Row
                  className={`start-sm center-md middle-sm ${styles.service__interior}`}
                >
                  <Col className={`col-sm-12 ${styles.online}`}>
                    {service.allowOnline && (
                      <Tag className="action">{onlineService}</Tag>
                    )}
                  </Col>

                  <Col className="col-sm-3 col-md-12">
                    <Image
                      src={imgPrefix(
                        `/images/post-request/${service.slug}.svg`
                      )}
                      alt={service.title}
                      width={60}
                      height={60}
                    />
                  </Col>

                  <Col className="col-sm-7 col-md-12 start-sm center-md">
                    <Paragraph
                      size="4"
                      title={service.title}
                      text={service.text}
                      className="no-space"
                    />
                  </Col>

                  <Col className="col-sm-2 hide-md center-sm">
                    <Image
                      src={imgPrefix('/images/shared/arrow-forward.svg')}
                      alt={button}
                      width={24}
                      height={24}
                    />
                  </Col>
                </Row>
              </FlatCardBody>
            </FlatCard>
          </a>
        </Col>
      ))}
    </Row>
  </Banner>
);
