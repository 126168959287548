import { parsePhoneNumberFromString } from 'libphonenumber-js';

type TFormatPhoneNumber = {
  phone: string;
};

export const formatPhoneNumber = (
  params: TFormatPhoneNumber,
  { phone } = params
) => {
  const phoneNumber = parsePhoneNumberFromString(phone, 'BR');

  return phoneNumber.format('NATIONAL');
};
